
import jsPDF from 'jspdf';
import { ProductSummary } from '../../queries/models/product-summary.model';
import { is } from 'date-fns/locale';
import { format } from 'date-fns';

export class KitchenPapersGenerator {

    backgroundColorGray = '#f5f5f5';
    backgroundColorWhite = '#ffffff';
    primaryTextColor = '#212121';
    secondaryTextColor = '#757575';

    pageHeight = 297;
    pageWidth = 210;
    pageMargin = 10;

    mealNameHeight = 8;
    subMealHeight = 5;

    currnetY = 0;
    prevY = 0;
    currentRowBottom = 0;

    public generateDoc(meals: ProductSummary[], date: Date): any {
        const doc = new jsPDF('p');

        // Set font encoding to UTF-8
        doc.setFont("Arial", "Unicode");
        this.currnetY = this.pageMargin;

        let headerText = `Zamówienia - ${format(date, 'dd.MM.yyyy')}`;
        this.addHeader(doc, headerText);

        doc.line(this.pageMargin, this.currnetY, this.pageWidth - this.pageMargin, this.currnetY);
        meals.forEach((meal, index) => {
            if ((this.currnetY + this.calculateHeight(meal)) > (this.pageHeight - this.pageMargin)
                || (index + 1 < meals.length && (this.currnetY + this.calculateHeight(meals[index + 1])) > (this.pageHeight - this.pageMargin))) {
                this.addNewPage(doc);
                this.currnetY = this.pageMargin;
            }

            this.addMealInfo(doc, meal, index % 2 === 1);

            if (index % 2 === 0 && index + 1 === meals.length) {
                this.currnetY += 4;
                doc.line(this.pageMargin, this.currentRowBottom + 4, this.pageWidth - this.pageMargin, this.currentRowBottom + 4);
                doc.line(this.pageWidth / 2, this.currnetY - 4, this.pageWidth / 2, this.currentRowBottom + 4);
            }
        });

        return doc;
    }

    private addNewPage(doc: jsPDF) {
        doc.addPage('p');
    }

    private addHeader(doc: jsPDF, text: string) {
        doc.setFontSize(18);
        doc.setFont('Arial', 'bold');
        doc.text(text, this.pageWidth / 2, this.currnetY + 10, { align: 'center' })
        this.currnetY += 20;
    }

    private addMealInfo(doc: jsPDF, meal: ProductSummary, isRightColumn = false) {
        this.prevY = this.currnetY;
        const x = isRightColumn
            ? this.pageWidth / 2 + this.pageMargin
            : this.pageMargin;

        doc.setFontSize(14);
        doc.setFont('Arial', 'bold');
        this.currnetY += this.mealNameHeight;
        doc.text(meal.label ?? '', x, this.currnetY);

        doc.setFontSize(12);
        for (let i = 0; i < meal.subMeals.length; i++) {
            const subMeal = meal.subMeals[i];
            this.currnetY += this.subMealHeight;
            doc.setFont('Arial', 'bold');
            doc.text(`${subMeal.value}x`, x, this.currnetY);
            doc.setFont('Arial', 'normal');
            doc.text(subMeal.label, x + 8, this.currnetY);
        }

        if (!isRightColumn) {
            this.currentRowBottom = this.currnetY;
            this.currnetY = this.prevY;
        } else if (this.currentRowBottom > this.currnetY) {
            this.currnetY = this.currentRowBottom;
        }

        if (isRightColumn) {
            this.currnetY += 4;
            doc.line(this.pageMargin, this.currnetY, this.pageWidth - this.pageMargin, this.currnetY);
            doc.line(this.pageWidth / 2, this.prevY, this.pageWidth / 2, this.currnetY);
        }
    }

    private calculateHeight(meal: ProductSummary): number {
        return this.mealNameHeight + this.subMealHeight * meal.subMeals.length;
    }
}