import { useCallback, useMemo } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { classNames } from "primereact/utils";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import { useToast } from "../../components/ui/toast-context-provider";
import FormikInputTextarea from "../../components/ui/formik/FormikInputTextarea";
import FormikInputNumber from "../../components/ui/formik/FormikInputNumber";
import { ProductVariant } from "../../queries/models/product.variant.model";

export interface ProductVariantEditFormProps {
  productVariant: ProductVariant;
  isSubmitting?: boolean;
  onSave: (form: ProductVariant) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
}

export function ProductVariantForm({
  productVariant,
  onSave,
  onCancel,
  onDelete,
}: ProductVariantEditFormProps) {
  const lg = false;
  const toast = useToast();
  const hasWriteAccess = true;

  const initialValues: ProductVariant = {
    id: productVariant?.id ?? 0,
    name: productVariant?.name ?? "",
    dateCreated: productVariant?.dateCreated ?? new Date(),
    dateModified: productVariant?.dateModified ?? new Date(),
    description: productVariant?.description ?? "",
    isArchived: productVariant?.isArchived ?? false,
    lastModifiedById: productVariant?.lastModifiedById ?? 0,
  };
  const validationSchema = Yup.object({
    name: Yup.string().min(3, "Podaj minimum 3 znaki").required("Required"),
  });

  const onSubmit = useCallback(
    (values: ProductVariant) => {
      return onSave(values);
    },
    [onSave]
  );

  return (
    <div className="p-2 h-full w-full">
      {true && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form
              autoComplete="off"
              className={classNames(
                "flex",
                lg ? "flex-column" : " flex-column-reverse"
              )}
            >
              <EditContentHeader
                header={
                  productVariant?.id
                    ? productVariant.name
                    : "Dodaj nowego konia"
                }
                showDeleteButton={hasWriteAccess && !!productVariant.id}
                saveButtonDisabled={
                  !hasWriteAccess || !formik.dirty || !formik.isValid
                }
                onSaveClick={async () => {
                  if (!formik.isValid) {
                    toast.current?.show({
                      severity: "error",
                      detail: "Form invalid",
                    });
                    return;
                  }
                  return formik.submitForm();
                }}
                onCancelClick={onCancel}
                onDeleteClick={onDelete}
              />

              <div className="formgrid grid">
                <div className="field col-12">
                  <FormikInputText
                    label="Nazwa"
                    name="name"
                    validationSchema={validationSchema}
                    isIndependent
                    autoComplete="off"
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12">
                  <FormikInputTextarea
                    label="Opis"
                    name="Description"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
