import {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Loader } from "../../components/ui/Loader";
import { DataTable } from "primereact/datatable";
import { TableHeader } from "../../components/ui/table-header";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import Enumerable from "linq";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { FilterMatchMode } from "primereact/api";
import { Holiday } from "../../queries/models/holiday.model";
import { HolidayForm } from "./HolidayForm";
import { useParams } from "react-router-dom";
import {
  useAddHolidayMutation,
  useHolidaysQuery,
  useDeleteHolidayMutation,
  useEditHolidayMutation,
} from "../../queries/holidays.query";
import { format } from "date-fns";

export function Holidays() {
  const queryClient = useQueryClient();
  const holidaysQuery = useHolidaysQuery();
  const addHolidayMutation = useAddHolidayMutation();
  const editHolidayMutation = useEditHolidayMutation();
  const deleteHolidayMutation = useDeleteHolidayMutation();
  const toast = useToast();
  const [selectedHoliday, setSelectedHoliday] = useState<Holiday>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const params = useParams();
  const queryId = useMemo(
    () => (params?.id ? Number(params.id) : undefined),
    [params]
  );
  useEffect(() => {
    if (queryId) {
      const rider = holidaysQuery.data?.find((x) => x.id === queryId);

      if (rider) {
        setSelectedHoliday(rider);
        setIsModalOpen(true);
      }
    }
  }, [queryId, holidaysQuery.data]);

  const handleGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      const value = e.target.value;
      let _filters = filters;
      _filters.global.value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );

  const handleAddNewHoliday = () => {
    setSelectedHoliday({
      name: "",
    } as Holiday);
  };

  const handleSaveHoliday = (data: Holiday) => {
    return data?.id ? editExistingHoliday(data) : saveNewHoliday(data);
  };

  const saveNewHoliday = (data: Holiday) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedHoliday(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Zapisane!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: Holiday = {
      id: data.id,
      name: data.name,
      date: data.date,
    };

    return addHolidayMutation.mutateAsync(request, mutateOptions);
  };

  const editExistingHoliday = (data: Holiday) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedHoliday(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Zapisane!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: Holiday = {
      id: data.id,
      name: data.name,
      date: data.date,
    };

    return editHolidayMutation.mutateAsync(request, mutateOptions);
  };

  const mobileHolidayFormModalProps: CustomModalProps = {
    header: selectedHoliday?.id ? selectedHoliday.name : "Dodaj nowego klienta",
    onClose: () => {
      setSelectedHoliday(undefined);
      setIsModalOpen(false);
    },
    isOpen: isModalOpen,
    body: (
      <HolidayForm
        holiday={selectedHoliday ?? ({} as Holiday)}
        onSave={handleSaveHoliday}
        onCancel={() => {
          setSelectedHoliday(undefined);
          setIsModalOpen(false);
        }}
        onDelete={() => setIsDeleteConfirmationModalOpen(true)}
        isSubmitting={isSubmitting}
      />
    ),
  };

  const handleDeleteHoliday = useCallback(() => {
    if (selectedHoliday) {
      deleteHolidayMutation.mutateAsync(selectedHoliday.id, {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          toast.current?.show({
            severity: "success",
            detail: "Zapisane!",
          });
          setSelectedHoliday(undefined);
          setIsDeleteConfirmationModalOpen(false);
          setIsModalOpen(false);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error,
          });
        },
      });
    }
  }, [deleteHolidayMutation, queryClient, selectedHoliday, toast]);

  const mobileModalConfirmationFormProps: CustomModalProps = {
    header: "Potwierdź",
    body: "Czy chcesz usunąć?",
    height: "160px",
    width: "50%",
    isOpen: isDeleteConfirmationModalOpen,
    confirmation: true,
    onClose: () => setIsDeleteConfirmationModalOpen(false),
    centered: true,
    justified: true,
    onConfirm: handleDeleteHoliday,
  };

  return (
    <>
      {holidaysQuery.isLoading ? (
        <div className="h-full flex align-items-center">
          <Loader type="spinner" />
        </div>
      ) : (
        <div className="h-full">
          {!!mobileHolidayFormModalProps && (
            <CustomModal {...mobileHolidayFormModalProps} />
          )}
          {!!mobileModalConfirmationFormProps && (
            <CustomModal {...mobileModalConfirmationFormProps} />
          )}
          <div className="p-1 h-3rem">
            <InputText
              className="w-full"
              placeholder="Filtr globalny"
              value={globalFilterValue}
              onChange={handleGlobalFilterChange}
            />
          </div>

          <div style={{ height: "calc(100% - 3rem)" }}>
            <DataTable
              value={holidaysQuery.data}
              resizableColumns={true}
              scrollable={true}
              scrollHeight="flex"
              selectionMode="single"
              selection={selectedHoliday}
              onSelectionChange={(e) => {
                setSelectedHoliday(e.value as Holiday);
                setIsModalOpen(true);
              }}
              filters={filters}
              globalFilterFields={["name", "address", "phone"]}
              header={
                <TableHeader
                  header=""
                  showAddButton={true}
                  onClickAdd={() => {
                    handleAddNewHoliday();
                    setIsModalOpen(true);
                  }}
                />
              }
            >
              <Column field="name" header="Nazwa" sortable />
              <Column
                field="date"
                header="Data"
                sortable
                body={(x) => format(x.date, "dd-MM-yyyy")}
              />
            </DataTable>
          </div>
        </div>
      )}
    </>
  );
}
