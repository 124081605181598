import {
  Field,
  ErrorMessage,
  FastField,
  FormikContextType,
  FieldInputProps,
  FieldMetaProps,
} from "formik";
import TextError from "./TextError";
import { FormInputLabel } from "./FormInputLabel";
import { classNames } from "primereact/utils";
import { useMemo } from "react";
import { SelectItem } from "primereact/selectitem";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import * as Yup from "yup";

export interface FormikDropdownProps extends DropdownProps {
  label: string;
  name: string;
  options?: SelectItem[];
  validationSchema?: Yup.ObjectSchema<any, Yup.AnyObject, any, "">;
  isIndependent?: boolean;
  afterChange?: (value: any) => void;
  showLabel?: boolean;
}

function FormikDropDown({
  label,
  name,
  options = [],
  validationSchema,
  isIndependent = false,
  afterChange,
  showLabel = true,
  ...rest
}: FormikDropdownProps) {
  const Component = useMemo(() => {
    return isIndependent ? FastField : Field;
  }, [isIndependent]);
  return (
    <>
      {showLabel && (
        <FormInputLabel nameFor={name} validationSchema={validationSchema}>
          {label}
        </FormInputLabel>
      )}
      <Component name={name}>
        {({
          form,
          field,
          meta,
        }: {
          form: FormikContextType<any>;
          field: FieldInputProps<any>;
          meta: FieldMetaProps<any>;
        }) => {
          return (
            <Dropdown
              id={name}
              className={classNames({
                "w-full": true,
                "p-invalid": meta.error && meta.touched,
              })}
              type="text"
              options={options}
              placeholder={label}
              value={field.value}
              onBlur={(e) => form.setFieldTouched(name, true)}
              onChange={(e) => {
                form.setFieldValue(name, e.value);
                if (afterChange) {
                  afterChange(e.value);
                }
              }}
              {...rest}
            />
          );
        }}
      </Component>
      <ErrorMessage component={TextError} name={name} />
    </>
  );
}

export default FormikDropDown;
