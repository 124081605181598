import {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Loader } from "../../components/ui/Loader";
import { DataTable } from "primereact/datatable";
import { TableHeader } from "../../components/ui/table-header";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import Enumerable from "linq";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { FilterMatchMode } from "primereact/api";
import { Customer } from "../../queries/models/customer.model";
import { CustomerForm } from "./CusstomerForm";
import { useParams } from "react-router-dom";
import {
  useAddCustomerMutation,
  useCustomersQuery,
  useDeleteCustomerMutation,
  useEditCustomerMutation,
} from "../../queries/customers.query";
import { useActiveUsersAsSelectOptionQuery } from "../../queries/users.query";
import { useActivesubscriptionsAsSelectOptionQuery } from "../../queries/subscriptions.query";
import { useActiveProductsVariantsAsSelectOptionQuery } from "../../queries/products-variants.query";
import { Subscription } from "../../queries/models/subscription.model";

export function Customers() {
  const queryClient = useQueryClient();
  const customersQuery = useCustomersQuery();
  const driverOptionsQuery = useActiveUsersAsSelectOptionQuery();
  const productVariantsOptionsQuery =
    useActiveProductsVariantsAsSelectOptionQuery();
  const addCustomerMutation = useAddCustomerMutation();
  const editCustomerMutation = useEditCustomerMutation();
  const deleteCustomerMutation = useDeleteCustomerMutation();
  const toast = useToast();
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const params = useParams();
  const queryId = useMemo(
    () => (params?.id ? Number(params.id) : undefined),
    [params]
  );
  useEffect(() => {
    if (queryId) {
      const rider = customersQuery.data?.find((x) => x.id === queryId);

      if (rider) {
        setSelectedCustomer(rider);
        setIsModalOpen(true);
      }
    }
  }, [queryId, customersQuery.data]);

  const handleGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      const value = e.target.value;
      let _filters = filters;
      _filters.global.value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );

  const handleAddNewCustomer = () => {
    setSelectedCustomer({
      name: "",
    } as Customer);
  };

  const handleSaveCustomer = (data: Customer) => {
    return data?.id ? editExistingCustomer(data) : saveNewCustomer(data);
  };

  const saveNewCustomer = (data: Customer) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedCustomer(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Zapisane!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: Customer = {
      id: data.id,
      name: data.name,
      address: data.address,
      dateCreated: data.dateCreated,
      dateModified: data.dateModified,
      phone: data.phone,
      email: data.email,
      lastModifiedById: data.lastModifiedById,
      deliveries: data.deliveries,
      defaultDriverId: data.defaultDriverId,
      productVariantId: data.productVariantId,
      deliverySequence: data.deliverySequence,
      addresses: data.addresses,
      comment: data.comment,
    };

    return addCustomerMutation.mutateAsync(request, mutateOptions);
  };

  const editExistingCustomer = (data: Customer) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedCustomer(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Zapisane!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: Customer = {
      id: data.id,
      name: data.name,
      address: data.address,
      dateCreated: data.dateCreated,
      dateModified: data.dateModified,
      phone: data.phone,
      email: data.email,
      lastModifiedById: data.lastModifiedById,
      deliveries: data.deliveries,
      defaultDriverId: data.defaultDriverId,
      productVariantId: data.productVariantId,
      deliverySequence: data.deliverySequence,
      addresses: data.addresses,
      comment: data.comment,
    };

    return editCustomerMutation.mutateAsync(request, mutateOptions);
  };

  const customerFormModalProps: CustomModalProps = {
    header: selectedCustomer?.id
      ? `${selectedCustomer.name}`
      : "Dodaj nowego klienta",
    onClose: () => {
      setSelectedCustomer(undefined);
      setIsModalOpen(false);
    },
    isOpen: isModalOpen,
    width: "100%",
    body: (
      <CustomerForm
        customer={selectedCustomer ?? ({} as Customer)}
        onSave={handleSaveCustomer}
        onCancel={() => {
          setSelectedCustomer(undefined);
          setIsModalOpen(false);
        }}
        onDelete={() => setIsDeleteConfirmationModalOpen(true)}
        isSubmitting={isSubmitting}
        driverOptions={driverOptionsQuery.data ?? []}
        productsVariantsOptions={productVariantsOptionsQuery.data ?? []}
      />
    ),
  };

  const handleDeleteCustomer = useCallback(() => {
    if (selectedCustomer) {
      deleteCustomerMutation.mutateAsync(selectedCustomer.id, {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          toast.current?.show({
            severity: "success",
            detail: "Zapisane!",
          });
          setSelectedCustomer(undefined);
          setIsDeleteConfirmationModalOpen(false);
          setIsModalOpen(false);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error,
          });
        },
      });
    }
  }, [deleteCustomerMutation, queryClient, selectedCustomer, toast]);

  const mobileModalConfirmationFormProps: CustomModalProps = {
    header: "Potwierdź",
    body: "Czy chcesz usunąć?",
    height: "160px",
    width: "50%",
    isOpen: isDeleteConfirmationModalOpen,
    confirmation: true,
    onClose: () => setIsDeleteConfirmationModalOpen(false),
    centered: true,
    justified: true,
    onConfirm: handleDeleteCustomer,
  };

  return (
    <>
      {customersQuery.isLoading ? (
        <div className="h-full flex align-items-center">
          <Loader type="spinner" />
        </div>
      ) : (
        <div className="h-full">
          {!!customerFormModalProps && (
            <CustomModal {...customerFormModalProps} />
          )}
          {!!mobileModalConfirmationFormProps && (
            <CustomModal {...mobileModalConfirmationFormProps} />
          )}
          <div className="p-1 h-3rem">
            <InputText
              className="w-full"
              placeholder="Filtr globalny"
              value={globalFilterValue}
              onChange={handleGlobalFilterChange}
            />
          </div>

          <div style={{ height: "calc(100% - 3rem)" }}>
            <DataTable
              value={customersQuery.data}
              resizableColumns={true}
              scrollable={true}
              scrollHeight="flex"
              selectionMode="single"
              selection={selectedCustomer}
              onSelectionChange={(e) => {
                setSelectedCustomer(e.value as Customer);
                setIsModalOpen(true);
              }}
              filters={filters}
              globalFilterFields={["name", "address", "phone"]}
              header={
                <TableHeader
                  header="Klienci"
                  showAddButton={true}
                  onClickAdd={() => {
                    handleAddNewCustomer();
                    setIsModalOpen(true);
                  }}
                />
              }
            >
              <Column
                field="name"
                header="Nazwa"
                sortable
                body={(x) => `${x.name}`}
              />
              <Column field="phone" header="Telefon" sortable />
              <Column
                field="reamingDeliveries"
                header="Aktywny"
                sortable
                body={(x) => (
                  <span
                    className={
                      x.reamingDeliveries < 3 && x.hasContinuation
                        ? "text-red-600 font-bold"
                        : ""
                    }
                  >
                    {x.reamingDeliveries > 0 || x.hasContinuation
                      ? `tak (${x.reamingDeliveries})`
                      : "nie"}
                  </span>
                )}
              />
            </DataTable>
          </div>
        </div>
      )}
    </>
  );
}
