import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { SelectItem } from "primereact/selectitem";
import { Customer } from "../queries/models/customer.model";
import { ProductVariant } from "../queries/models/product.variant.model";

class ProductsVariantsService extends BaseService {

    async getAllActive(): Promise<ProductVariant[]> {
        const url = `${this.endpointPath}/active`;
        return this.http.get<Customer[]>(url).then((response: any) => response.data);
    }

    async getAllActiveAsOptions(): Promise<SelectItem[]> {
        const url = `${this.endpointPath}/active-as-options`;
        return this.http.get<SelectItem[]>(url).then((response: any) => response.data);
    }

    async add(request: ProductVariant) {
        const url = `${this.endpointPath}/create`;
        return this.http.post<ProductVariant>(url, request).then((response: any) => response.data);
    }
    async edit(request: ProductVariant) {
        const url = `${this.endpointPath}/update`;
        return this.http.put<ProductVariant>(url, request).then((response: any) => response.data);
    }

    async delete(id: number) {
        const url = `${this.endpointPath}/delete/${id}`;
        return this.http.delete(url).then((response: any) => response.data);
    }
}

export const productsVariantsService = new ProductsVariantsService(API_ENDPOINTS.PRODUCTS_VARIANTS);