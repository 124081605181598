import { useMutation, useQuery } from 'react-query';
import { Customer } from './models/customer.model';
import { productsService } from '../services/products.service';
import { Product } from './models/product.model';

export function useProductsQuery() {
    return useQuery<Product[]>(["products"], () =>
        productsService.getAllActive());
}

export function useActiveProductsAsSelectOptionQuery() {
    return useQuery(
        ["products", "products-options"],
        () => productsService.getAllActiveAsOptions()
    )
}

export function useAddProductMutation() {
    return useMutation((request: Product) => productsService.add(request));
}

export function useEditProductMutation() {
    return useMutation((request: Product) => productsService.edit(request));
}

export function useDeleteProductMutation() {
    return useMutation((id: number) => productsService.delete(id));
}