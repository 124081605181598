export const API_ENDPOINTS: Record<string, string> = {
    AUTHENTICATION: "auth",
    APP_SETTINGS: "app-settings",
    USERS: "users",
    CUSTOMERS: "customers",
    HOLIDAYS: "holidays",
    CUSTOMER_HOLIDAYS: "customer-holidays",
    PRODUCTS: "products",
    PRODUCTS_VARIANTS: "products-variants",
    SUBSCIPTIONS: "subscriptions",
    DELIVERIES: "deliveries",
}