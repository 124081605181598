import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { CreateUserRequest } from "../queries/models/create-user-request.model";
import { UpdateUserRequest } from "../queries/models/update-user-request";
import { SelectItem } from "primereact/selectitem";
import { Customer } from "../queries/models/customer.model";
import { Product } from "../queries/models/product.model";

class ProductsService extends BaseService {

    async getAllActive(): Promise<Product[]> {
        const url = `${this.endpointPath}/active`;
        return this.http.get<Customer[]>(url).then((response: any) => response.data);
    }

    async getAllActiveAsOptions(): Promise<SelectItem[]> {
        const url = `${this.endpointPath}/active-as-options`;
        return this.http.get<SelectItem[]>(url).then((response: any) => response.data);
    }

    async add(request: Product) {
        const url = `${this.endpointPath}/create`;
        return this.http.post<Product>(url, request).then((response: any) => response.data);
    }
    async edit(request: Product) {
        const url = `${this.endpointPath}/update`;
        return this.http.put<Product>(url, request).then((response: any) => response.data);
    }

    async delete(id: number) {
        const url = `${this.endpointPath}/delete/${id}`;
        return this.http.delete(url).then((response: any) => response.data);
    }
}

export const productsService = new ProductsService(API_ENDPOINTS.PRODUCTS);