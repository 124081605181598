import { useMutation, useQuery } from 'react-query';
import { CustomerHoliday } from './models/customer-holiday.model';
import { customerHolidaysService } from '../services/customer-holidays.service';

export function useCustomerHolidaysQuery() {
    return useQuery<CustomerHoliday[]>(["customer-holidays"], () =>
        customerHolidaysService.getAllActive());
}

export function useAddCustomerHolidayMutation() {
    return useMutation((request: CustomerHoliday) => customerHolidaysService.add(request));
}

export function useEditCustomerHolidayMutation() {
    return useMutation((request: CustomerHoliday) => customerHolidaysService.edit(request));
}

export function useDeleteCustomerHolidayMutation() {
    return useMutation((id: number) => customerHolidaysService.delete(id));
}