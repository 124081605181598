import {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Loader } from "../../components/ui/Loader";
import { DataTable } from "primereact/datatable";
import { TableHeader } from "../../components/ui/table-header";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import Enumerable from "linq";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { FilterMatchMode } from "primereact/api";
import { Product } from "../../queries/models/product.model";
import { ProductForm } from "./ProductsForm";
import { useParams } from "react-router-dom";
import {
  useAddProductMutation,
  useProductsQuery,
  useDeleteProductMutation,
  useEditProductMutation,
} from "../../queries/products.query";

export function Products() {
  const queryClient = useQueryClient();
  const productsQuery = useProductsQuery();
  const addProductMutation = useAddProductMutation();
  const editProductMutation = useEditProductMutation();
  const deleteProductMutation = useDeleteProductMutation();
  const toast = useToast();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const params = useParams();
  const queryId = useMemo(
    () => (params?.id ? Number(params.id) : undefined),
    [params]
  );
  useEffect(() => {
    if (queryId) {
      const rider = productsQuery.data?.find((x) => x.id === queryId);

      if (rider) {
        setSelectedProduct(rider);
        setIsModalOpen(true);
      }
    }
  }, [queryId, productsQuery.data]);

  const handleGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      const value = e.target.value;
      let _filters = filters;
      _filters.global.value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );

  const handleAddNewProduct = () => {
    setSelectedProduct({
      name: "",
    } as Product);
  };

  const handleSaveProduct = (data: Product) => {
    return data?.id ? editExistingProduct(data) : saveNewProduct(data);
  };

  const saveNewProduct = (data: Product) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedProduct(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Zapisane!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: Product = {
      id: data.id,
      name: data.name,
      description: data.description,
      dateCreated: data.dateCreated,
      dateModified: data.dateModified,
      isArchived: data.isArchived,
      pricePerDay: data.pricePerDay,
      lastModifiedById: data.lastModifiedById,
      pricePer20Days: data.pricePer20Days,
      pricePer5Days: data.pricePer5Days,
    };

    return addProductMutation.mutateAsync(request, mutateOptions);
  };

  const editExistingProduct = (data: Product) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedProduct(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Zapisane!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: Product = {
      id: data.id,
      name: data.name,
      dateCreated: data.dateCreated,
      dateModified: data.dateModified,
      description: data.description,
      isArchived: data.isArchived,
      pricePerDay: data.pricePerDay,
      lastModifiedById: data.lastModifiedById,
      pricePer20Days: data.pricePer20Days,
      pricePer5Days: data.pricePer5Days,
    };

    return editProductMutation.mutateAsync(request, mutateOptions);
  };

  const mobileProductFormModalProps: CustomModalProps = {
    header: selectedProduct?.id ? selectedProduct.name : "Dodaj nowego klienta",
    onClose: () => {
      setSelectedProduct(undefined);
      setIsModalOpen(false);
    },
    isOpen: isModalOpen,
    body: (
      <ProductForm
        product={selectedProduct ?? ({} as Product)}
        onSave={handleSaveProduct}
        onCancel={() => {
          setSelectedProduct(undefined);
          setIsModalOpen(false);
        }}
        onDelete={() => setIsDeleteConfirmationModalOpen(true)}
        isSubmitting={isSubmitting}
      />
    ),
  };

  const handleDeleteProduct = useCallback(() => {
    if (selectedProduct) {
      deleteProductMutation.mutateAsync(selectedProduct.id, {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          toast.current?.show({
            severity: "success",
            detail: "Zapisane!",
          });
          setSelectedProduct(undefined);
          setIsDeleteConfirmationModalOpen(false);
          setIsModalOpen(false);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error,
          });
        },
      });
    }
  }, [deleteProductMutation, queryClient, selectedProduct, toast]);

  const mobileModalConfirmationFormProps: CustomModalProps = {
    header: "Potwierdź",
    body: "Czy chcesz usunąć?",
    height: "160px",
    width: "50%",
    isOpen: isDeleteConfirmationModalOpen,
    confirmation: true,
    onClose: () => setIsDeleteConfirmationModalOpen(false),
    centered: true,
    justified: true,
    onConfirm: handleDeleteProduct,
  };

  return (
    <>
      {productsQuery.isLoading ? (
        <div className="h-full flex align-items-center">
          <Loader type="spinner" />
        </div>
      ) : (
        <div className="h-full">
          {!!mobileProductFormModalProps && (
            <CustomModal {...mobileProductFormModalProps} />
          )}
          {!!mobileModalConfirmationFormProps && (
            <CustomModal {...mobileModalConfirmationFormProps} />
          )}
          <div className="p-1 h-3rem">
            <InputText
              className="w-full"
              placeholder="Filtr globalny"
              value={globalFilterValue}
              onChange={handleGlobalFilterChange}
            />
          </div>

          <div style={{ height: "calc(100% - 3rem)" }}>
            <DataTable
              value={productsQuery.data}
              resizableColumns={true}
              scrollable={true}
              scrollHeight="flex"
              selectionMode="single"
              selection={selectedProduct}
              onSelectionChange={(e) => {
                setSelectedProduct(e.value as Product);
                setIsModalOpen(true);
              }}
              filters={filters}
              globalFilterFields={["name", "address", "phone"]}
              header={
                <TableHeader
                  header=""
                  showAddButton={true}
                  onClickAdd={() => {
                    handleAddNewProduct();
                    setIsModalOpen(true);
                  }}
                />
              }
            >
              <Column field="name" header="Nazwa" sortable />
              <Column field="pricePerDay" header="Cena [zł]" sortable />
            </DataTable>
          </div>
        </div>
      )}
    </>
  );
}
