import { useMutation, useQuery } from 'react-query';
import { holidaysService } from '../services/holidays.service';
import { Holiday } from './models/holiday.model';

export function useHolidaysQuery() {
    return useQuery<Holiday[]>(["holidays"], () =>
        holidaysService.getAllActive());
}

export function useActiveHolidaysAsSelectOptionQuery() {
    return useQuery(
        ["holidays", "holidays-options"],
        () => holidaysService.getAllActiveAsOptions()
    )
}

export function useAddHolidayMutation() {
    return useMutation((request: Holiday) => holidaysService.add(request));
}

export function useEditHolidayMutation() {
    return useMutation((request: Holiday) => holidaysService.edit(request));
}

export function useDeleteHolidayMutation() {
    return useMutation((id: number) => holidaysService.delete(id));
}