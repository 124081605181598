import { useMutation, useQuery } from 'react-query';
import { subscriptionsService } from '../services/subscriptions.service';
import { Subscription } from './models/subscription.model';

export function useSubscriptionsQuery() {
    return useQuery<Subscription[]>(["subscriptions"], () =>
        subscriptionsService.getAllActive());
}

export function useActivesubscriptionsAsSelectOptionQuery() {
    return useQuery(
        ["subscriptions", "subscriptions-options"],
        () => subscriptionsService.getAllActiveAsOptions()
    )
}

export function useAddSubscriptionMutation() {
    return useMutation((request: Subscription) => subscriptionsService.add(request));
}

export function useEditSubscriptionMutation() {
    return useMutation((request: Subscription) => subscriptionsService.edit(request));
}

export function usePaySubscriptionMutation() {
    return useMutation((id: number) => subscriptionsService.pay(id));
}

export function useDeleteSubscriptionMutation() {
    return useMutation((id: number) => subscriptionsService.delete(id));
}