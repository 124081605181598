import { useMutation, useQuery } from 'react-query';
import { ProductVariant } from './models/product.variant.model';
import { productsVariantsService } from '../services/products-variants.service';

export function useProductsVariantsQuery() {
    return useQuery<ProductVariant[]>(["products-variants"], () =>
        productsVariantsService.getAllActive());
}

export function useActiveProductsVariantsAsSelectOptionQuery() {
    return useQuery(
        ["products-variants", "products-variants-options"],
        () => productsVariantsService.getAllActiveAsOptions()
    )
}

export function useAddProductVariantsMutation() {
    return useMutation((request: ProductVariant) => productsVariantsService.add(request));
}

export function useEditProductVariantsMutation() {
    return useMutation((request: ProductVariant) => productsVariantsService.edit(request));
}

export function useDeleteProductVariantsMutation() {
    return useMutation((id: number) => productsVariantsService.delete(id));
}