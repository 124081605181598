import { useCallback, useMemo } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { classNames } from "primereact/utils";
import { useToast } from "../../components/ui/toast-context-provider";
import { Subscription } from "../../queries/models/subscription.model";
import { SelectItem } from "primereact/selectitem";
import FormikDropDown from "../../components/ui/formik/FormikDropdown";
import FormikCalendar from "../../components/ui/formik/FormikCalendar";
import FormikInputSwitch from "../../components/ui/formik/FormikInputSwitch";
import { SelectButton } from "primereact/selectbutton";
import { Period } from "../../queries/models/enums/period.enum";
import { Product } from "../../queries/models/product.model";
import { deliveriesService } from "../../services/deliveries.service";

export interface EditFormProps {
  subscription: Subscription;
  isSubmitting?: boolean;
  productOptions: SelectItem[] | undefined;
  customerOptions: SelectItem[] | undefined;
  dayCountOptions: SelectItem[] | undefined;
  onSave: (form: Subscription) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
}

export function SubscriptionForm({
  subscription,
  productOptions = [],
  customerOptions = [],
  dayCountOptions = [],
  onSave,
  onCancel,
  onDelete,
}: EditFormProps) {
  const lg = false;
  const toast = useToast();
  const hasWriteAccess = true;

  const calculateDailyPrice = useCallback(
    (values: any) => {
      const product = productOptions.find(
        (product) => product.value === values.productId
      );

      var periodPrice = 0;
      var productModel = (product as any)?.model as Product;
      if (productModel === undefined) return 0;

      var daysCount = values.daysCount as Period;
      switch (daysCount) {
        case Period.Day1:
          periodPrice = productModel.pricePerDay ?? 0;
          break;
        case Period.Day5:
          periodPrice = productModel.pricePer5Days ?? 0;
          break;
        case Period.Day20:
          periodPrice = productModel.pricePer20Days ?? 0;
          break;
        default:
          break;
      }

      if (periodPrice === 0 || !daysCount) {
        return 0;
      }

      let resultPrice = periodPrice * 0.2;

      if (values.forBreakfast) resultPrice += periodPrice * 0.1;
      if (values.forSecondBreakfast) resultPrice += periodPrice * 0.1;
      if (values.forLunch) resultPrice += periodPrice * 0.3;
      if (values.forAfternoonTea) resultPrice += periodPrice * 0.1;
      if (values.forDinner) resultPrice += periodPrice * 0.2;

      return resultPrice;
    },
    [productOptions]
  );

  const calculateMonthlyPrice = useCallback(
    async (values: any) => {
      const product = productOptions.find(
        (product) => product.value === values.productId
      );
      var productModel = (product as any)?.model as Product;
      var period = values.daysCount as Period;

      if (period !== Period.Month) {
        return 0;
      }

      const daysCount = await deliveriesService.getMonthlyDeliveriesCount({
        customerId: values.customerId,
        startDate: values.startDate,
        onMonday: values.onMonday,
        onTuesday: values.onTuesday,
        onWednesday: values.onWednesday,
        onThursday: values.onThursday,
        onFriday: values.onFriday,
        onSaturday: values.onSaturday,
        onSunday: values.onSunday,
      });

      return productModel.pricePerDay * daysCount;
    },
    [productOptions]
  );

  const mealOptions = useMemo(() => {
    return [
      { name: "1Śn", value: 1 },
      { name: "2Śn", value: 2 },
      { name: "Ob", value: 3 },
      { name: "Pod", value: 4 },
      { name: "Kol", value: 5 },
    ];
  }, []);

  const dayOptions = useMemo(() => {
    return [
      { name: "pn", value: 1 },
      { name: "wt", value: 2 },
      { name: "śr", value: 3 },
      { name: "cz", value: 4 },
      { name: "pt", value: 5 },
      { name: "sb", value: 6 },
      { name: "nd", value: 7 },
    ];
  }, []);

  const getMealOptions = useCallback((sub: Subscription) => {
    const options = [];
    if (sub.forBreakfast) options.push(1);
    if (sub.forSecondBreakfast) options.push(2);
    if (sub.forLunch) options.push(3);
    if (sub.forAfternoonTea) options.push(4);
    if (sub.forDinner) options.push(5);
    return options;
  }, []);

  const getDayOptions = useCallback((sub: Subscription) => {
    const options = [];
    if (sub.onMonday) options.push(1);
    if (sub.onTuesday) options.push(2);
    if (sub.onWednesday) options.push(3);
    if (sub.onThursday) options.push(4);
    if (sub.onFriday) options.push(5);
    if (sub.onSaturday) options.push(6);
    if (sub.onSunday) options.push(7);
    return options;
  }, []);

  const handleSetMealOptions = useCallback((formik: any, options: any[]) => {
    if (options.includes(1)) formik.setFieldValue("forBreakfast", true);
    else formik.setFieldValue("forBreakfast", false);

    if (options.includes(2)) formik.setFieldValue("forSecondBreakfast", true);
    else formik.setFieldValue("forSecondBreakfast", false);

    if (options.includes(3)) formik.setFieldValue("forLunch", true);
    else formik.setFieldValue("forLunch", false);

    if (options.includes(4)) formik.setFieldValue("forAfternoonTea", true);
    else formik.setFieldValue("forAfternoonTea", false);

    if (options.includes(5)) formik.setFieldValue("forDinner", true);
    else formik.setFieldValue("forDinner", false);
  }, []);

  const handleSetDayOptions = useCallback((formik: any, options: any[]) => {
    if (options.includes(1)) formik.setFieldValue("onMonday", true);
    else formik.setFieldValue("onMonday", false);

    if (options.includes(2)) formik.setFieldValue("onTuesday", true);
    else formik.setFieldValue("onTuesday", false);

    if (options.includes(3)) formik.setFieldValue("onWednesday", true);
    else formik.setFieldValue("onWednesday", false);

    if (options.includes(4)) formik.setFieldValue("onThursday", true);
    else formik.setFieldValue("onThursday", false);

    if (options.includes(5)) formik.setFieldValue("onFriday", true);
    else formik.setFieldValue("onFriday", false);

    if (options.includes(6)) formik.setFieldValue("onSaturday", true);
    else formik.setFieldValue("onSaturday", false);

    if (options.includes(7)) formik.setFieldValue("onSunday", true);
    else formik.setFieldValue("onSunday", false);
  }, []);

  const getDaysCountEnum = useCallback((subscription: Subscription) => {
    if (!subscription) {
      return Period.Month;
    }

    if (subscription.isMonthly) {
      return Period.Month;
    } else {
      return subscription.daysCount;
    }
  }, []);

  const initialValues: Subscription = {
    id: subscription?.id ?? 0,
    customerId: subscription?.customerId ?? 0,
    dateCreated: subscription?.dateCreated ?? new Date(),
    dateModified: subscription?.dateModified ?? new Date(),
    daysCount: getDaysCountEnum(subscription),
    isArchived: subscription?.isArchived ?? false,
    isPaid: subscription?.isPaid ?? false,
    price: subscription?.price ?? 0,
    productId: subscription?.productId ?? 0,
    startDate: subscription?.startDate ?? new Date(),
    lastModifiedById: subscription?.lastModifiedById ?? 0,
    deliveries: subscription?.deliveries ?? [],
    forAfternoonTea: subscription?.forAfternoonTea ?? true,
    forBreakfast: subscription?.forBreakfast ?? true,
    forDinner: subscription?.forDinner ?? true,
    forLunch: subscription?.forLunch ?? true,
    forSecondBreakfast: subscription?.forSecondBreakfast ?? true,
    onMonday: subscription?.onMonday ?? true,
    onTuesday: subscription?.onTuesday ?? true,
    onWednesday: subscription?.onWednesday ?? true,
    onThursday: subscription?.onThursday ?? true,
    onFriday: subscription?.onFriday ?? true,
    onSaturday: subscription?.onSaturday ?? false,
    onSunday: subscription?.onSunday ?? false,
    isAutoRenewal: subscription?.isAutoRenewal ?? false,
    isMonthly: subscription?.isMonthly ?? false,
  };

  const validationSchema = Yup.object({
    customerId: Yup.number().required(),
    productId: Yup.number().required().nonNullable(),
    daysCount: Yup.number().required(),
    startDate: Yup.date().required(),
    isPaid: Yup.boolean().required(),
    isAutoRenewal: Yup.boolean().required(),
    forAfternoonTea: Yup.boolean().required(),
    forBreakfast: Yup.boolean().required(),
    forDinner: Yup.boolean().required(),
    forLunch: Yup.boolean().required(),
    forSecondBreakfast: Yup.boolean().required(),
    onMonday: Yup.boolean().required(),
    onTuesday: Yup.boolean().required(),
    onWednesday: Yup.boolean().required(),
    onThursday: Yup.boolean().required(),
    onFriday: Yup.boolean().required(),
    onSaturday: Yup.boolean().required(),
    onSunday: Yup.boolean().required(),
    isMonthly: Yup.boolean().required(),
  });

  const onSubmit = useCallback(
    async (values: Subscription) => {
      values.isMonthly = values.daysCount === Period.Month;
      values.price = values.isMonthly
        ? await calculateMonthlyPrice(values)
        : calculateDailyPrice(values);
      return onSave(values);
    },
    [calculateDailyPrice, calculateMonthlyPrice, onSave]
  );

  return (
    <div className="p-2 h-full w-full">
      {true && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form
              autoComplete="off"
              className={classNames(
                "flex",
                lg ? "flex-column" : " flex-column"
              )}
            >
              <EditContentHeader
                header={
                  subscription?.id ? "Edytuj subskryptcję" : "Dodaj subskrypcję"
                }
                showDeleteButton={hasWriteAccess && !!subscription.id}
                saveButtonDisabled={
                  !hasWriteAccess ||
                  !formik.dirty ||
                  !formik.isValid ||
                  !formik.values.productId
                }
                onSaveClick={async () => {
                  formik.validateForm();
                  if (!formik.isValid) {
                    toast.current?.show({
                      severity: "error",
                      detail: "Form invalid",
                    });
                    return;
                  }
                  return formik.submitForm();
                }}
                onCancelClick={onCancel}
                onDeleteClick={onDelete}
              />

              <div className="formgrid grid py-2">
                <div className="field col-12">
                  <FormikDropDown
                    label="Klient"
                    name="customerId"
                    validationSchema={validationSchema}
                    options={customerOptions}
                    disabled={!hasWriteAccess}
                    filter
                  />
                </div>
                <div className="field col-12">
                  <FormikDropDown
                    label="Produkt"
                    name="productId"
                    validationSchema={validationSchema}
                    options={productOptions}
                    disabled={!hasWriteAccess}
                    filter
                  />
                </div>
                <div className="field col-12 flex flex-row">
                  <div className="w-8">
                    <FormikCalendar
                      label="Początek"
                      name="startDate"
                      validationSchema={validationSchema}
                      locale="pl"
                      dateFormat="DD dd MM yy"
                      showTime={false}
                    />
                  </div>
                  <div className="w-4 flex justify-content-end">
                    <FormikInputSwitch
                      label="Odnawiaj"
                      name="isAutoRenewal"
                      validationSchema={validationSchema}
                      disabled={!hasWriteAccess}
                      checked={false}
                    />
                  </div>
                </div>
                <div className="field col-6">
                  <FormikDropDown
                    label="Ile dni"
                    name="daysCount"
                    validationSchema={validationSchema}
                    options={dayCountOptions}
                    disabled={!hasWriteAccess}
                    filter
                    appendTo={document.body}
                  />
                </div>
                <div className="field col-6 flex justify-content-end">
                  <FormikInputSwitch
                    label="Opłacona"
                    name="isPaid"
                    validationSchema={validationSchema}
                    disabled={!hasWriteAccess}
                    checked={false}
                  />
                </div>
                <div className="field col-12">
                  <div className="h-full flex flex-column">
                    <label className="font-medium m-1 w-full">
                      Które posiłki
                      <span className="text-red-500">{" *"}</span>
                    </label>
                    <SelectButton
                      className="w-full flex justify-content-center"
                      value={getMealOptions(formik.values)}
                      onChange={(e) => {
                        handleSetMealOptions(formik, e.value);
                      }}
                      optionLabel="name"
                      options={mealOptions}
                      multiple
                    />
                  </div>
                </div>
                <div className="field col-12">
                  <div className="h-full flex flex-column">
                    <label className="font-medium m-1 w-full">
                      Jakie dni
                      <span className="text-red-500">{" *"}</span>
                    </label>
                    <SelectButton
                      className="w-full flex justify-content-center"
                      value={getDayOptions(formik.values)}
                      onChange={(e) => {
                        handleSetDayOptions(formik, e.value);
                      }}
                      optionLabel="name"
                      options={dayOptions}
                      multiple
                    />
                  </div>
                </div>
                <div className="field col-12 flex justify-content-end pr-4 pt-2 text-xl font-bold">
                  <span>
                    Cena:{" "}
                    {formik.values.isMonthly
                      ? formik.values.price
                      : calculateDailyPrice(formik.values) >= 0
                      ? formik.values.price && formik.values.price >= 0
                        ? `${calculateDailyPrice(formik.values)} (${
                            formik.values.price
                          })`
                        : calculateDailyPrice(formik.values)
                      : "-"}{" "}
                    zł
                  </span>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}

      {subscription?.id && <div></div>}
    </div>
  );
}
