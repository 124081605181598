import {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Loader } from "../../components/ui/Loader";
import { DataTable } from "primereact/datatable";
import { TableHeader } from "../../components/ui/table-header";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import Enumerable from "linq";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { FilterMatchMode } from "primereact/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAddSubscriptionMutation,
  useSubscriptionsQuery,
  useDeleteSubscriptionMutation,
  useEditSubscriptionMutation,
} from "../../queries/subscriptions.query";
import {
  useActiveProductsAsSelectOptionQuery,
  useProductsQuery,
} from "../../queries/products.query";
import { Subscription } from "../../queries/models/subscription.model";
import { useActiveCustomersAsSelectOptionQuery } from "../../queries/customers.query";
import { SubscriptionForm } from "./SubscriptionForm";
import { format } from "date-fns";
import { Period } from "../../queries/models/enums/period.enum";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export function Subscriptions() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const subscriptionsQuery = useSubscriptionsQuery();
  const productsQuery = useProductsQuery();
  const customersOptionsQuery = useActiveCustomersAsSelectOptionQuery();
  const addSubscriptionMutation = useAddSubscriptionMutation();
  const editSubscriptionMutation = useEditSubscriptionMutation();
  const deleteSubscriptionMutation = useDeleteSubscriptionMutation();
  const [queryParamTriggered, setQueryParamTriggered] = useState(false);
  const toast = useToast();
  const [selectedSubscription, setSelectedSubscription] =
    useState<Subscription>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const productOptions = useMemo(() => {
    return Enumerable.from(productsQuery.data ?? [])
      .select((x) => ({ label: x.name, value: x.id, model: x }))
      .toArray();
  }, [productsQuery.data]);

  const query = useQuery();

  const queryEditSubscriptionId = useMemo(() => {
    return query.get("id") ? Number(query.get("id")) : undefined;
  }, [query]);
  const queryAddForCustomerId = useMemo(() => {
    return query.get("customerId")
      ? Number(query.get("customerId"))
      : undefined;
  }, [query]);

  useEffect(() => {
    if (queryEditSubscriptionId && !queryParamTriggered) {
      const sub = subscriptionsQuery.data?.find(
        (x) => x.id === queryEditSubscriptionId
      );

      if (sub) {
        setSelectedSubscription(sub);
        setIsModalOpen(true);
      }

      setQueryParamTriggered(true);
    }
  }, [queryEditSubscriptionId, queryParamTriggered, subscriptionsQuery.data]);

  useEffect(() => {
    if (queryAddForCustomerId && !queryParamTriggered) {
      setSelectedSubscription(undefined);
      setIsModalOpen(true);
      setQueryParamTriggered(true);
    }
  }, [
    queryAddForCustomerId,
    queryEditSubscriptionId,
    queryParamTriggered,
    subscriptionsQuery.data,
  ]);

  useEffect(() => {
    setQueryParamTriggered(false);
  }, [queryAddForCustomerId, queryEditSubscriptionId]);

  const dayCountOptions = useMemo(() => {
    return [
      { label: "1 dzień", value: Period.Day1 },
      { label: "5 dni", value: Period.Day5 },
      { label: "20 dni", value: Period.Day20 },
      { label: "Miesiąc kalendarzowy", value: Period.Month },
    ];
  }, []);

  const handleGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      const value = e.target.value;
      let _filters = filters;
      _filters.global.value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );

  const handleAddNewSubscription = () => {
    setSelectedSubscription(undefined);
  };

  const handleSaveSubscription = (data: Subscription) => {
    return data?.id
      ? editExistingSubscription(data)
      : saveNewSubscription(data);
  };

  const saveNewSubscription = (data: Subscription) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedSubscription(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Zapisane!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error,
        });
      },
      onSettled: () => {
        query.set("customerId", "");
        query.set("id", "");
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: Subscription = {
      id: data.id,
      customerId: data.customerId,
      dateCreated: data.dateCreated,
      dateModified: data.dateModified,
      daysCount: data.daysCount,
      isArchived: data.isArchived,
      isPaid: data.isPaid,
      productId: data.productId,
      startDate: data.startDate,
      lastModifiedById: data.lastModifiedById,
      deliveries: data.deliveries,
      price: data.price,
      forAfternoonTea: data.forAfternoonTea,
      forBreakfast: data.forBreakfast,
      forDinner: data.forDinner,
      forLunch: data.forLunch,
      forSecondBreakfast: data.forSecondBreakfast,
      onMonday: data.onMonday,
      onTuesday: data.onTuesday,
      onWednesday: data.onWednesday,
      onThursday: data.onThursday,
      onFriday: data.onFriday,
      onSaturday: data.onSaturday,
      onSunday: data.onSunday,
      isAutoRenewal: data.isAutoRenewal,
      isMonthly: data.isMonthly,
    };

    return addSubscriptionMutation.mutateAsync(request, mutateOptions);
  };

  const editExistingSubscription = (data: Subscription) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedSubscription(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Zapisane!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error,
        });
      },
      onSettled: () => {
        query.set("customerId", "");
        query.set("id", "");
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: Subscription = {
      id: data.id,
      customerId: data.customerId,
      dateCreated: data.dateCreated,
      dateModified: data.dateModified,
      daysCount: data.daysCount,
      isArchived: data.isArchived,
      isPaid: data.isPaid,
      productId: data.productId,
      startDate: data.startDate,
      lastModifiedById: data.lastModifiedById,
      deliveries: data.deliveries,
      price: data.price,
      forAfternoonTea: data.forAfternoonTea,
      forBreakfast: data.forBreakfast,
      forDinner: data.forDinner,
      forLunch: data.forLunch,
      forSecondBreakfast: data.forSecondBreakfast,
      onMonday: data.onMonday,
      onTuesday: data.onTuesday,
      onWednesday: data.onWednesday,
      onThursday: data.onThursday,
      onFriday: data.onFriday,
      onSaturday: data.onSaturday,
      onSunday: data.onSunday,
      isAutoRenewal: data.isAutoRenewal,
      isMonthly: data.isMonthly,
    };

    return editSubscriptionMutation.mutateAsync(request, mutateOptions);
  };

  const mobileSubscriptionFormModalProps: CustomModalProps = {
    header: selectedSubscription?.id
      ? "Edytuj subskrypcję"
      : "Dodaj nową subskrypcję",
    onClose: () => {
      setSelectedSubscription(undefined);
      setIsModalOpen(false);
    },
    isOpen: isModalOpen,
    body: (
      <SubscriptionForm
        subscription={
          selectedSubscription ??
          ({ customerId: queryAddForCustomerId } as Subscription)
        }
        onSave={handleSaveSubscription}
        productOptions={productOptions}
        customerOptions={customersOptionsQuery.data}
        dayCountOptions={dayCountOptions}
        onCancel={() => {
          setSelectedSubscription(undefined);
          setIsModalOpen(false);
        }}
        onDelete={() => setIsDeleteConfirmationModalOpen(true)}
        isSubmitting={isSubmitting}
      />
    ),
  };

  const handleDeleteSubscription = useCallback(() => {
    if (selectedSubscription) {
      deleteSubscriptionMutation.mutateAsync(selectedSubscription.id, {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          toast.current?.show({
            severity: "success",
            detail: "Zapisane!",
          });
          setSelectedSubscription(undefined);
          setIsDeleteConfirmationModalOpen(false);
          setIsModalOpen(false);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error,
          });
        },
      });
    }
  }, [deleteSubscriptionMutation, queryClient, selectedSubscription, toast]);

  const mobileModalConfirmationFormProps: CustomModalProps = {
    header: "Potwierdź",
    body: "Czy chcesz usunąć?",
    height: "160px",
    width: "50%",
    isOpen: isDeleteConfirmationModalOpen,
    confirmation: true,
    onClose: () => setIsDeleteConfirmationModalOpen(false),
    centered: true,
    justified: true,
    onConfirm: handleDeleteSubscription,
  };

  return (
    <>
      {subscriptionsQuery.isLoading ? (
        <div className="h-full flex align-items-center">
          <Loader type="spinner" />
        </div>
      ) : (
        <div className="h-full">
          {!!mobileSubscriptionFormModalProps && (
            <CustomModal {...mobileSubscriptionFormModalProps} />
          )}
          {!!mobileModalConfirmationFormProps && (
            <CustomModal {...mobileModalConfirmationFormProps} />
          )}
          <div className="p-1 h-3rem">
            <InputText
              className="w-full"
              placeholder="Filtr globalny"
              value={globalFilterValue}
              onChange={handleGlobalFilterChange}
            />
          </div>

          <div style={{ height: "calc(100% - 3rem)" }}>
            <DataTable
              value={subscriptionsQuery.data}
              resizableColumns={true}
              scrollable={true}
              scrollHeight="flex"
              selectionMode="single"
              selection={selectedSubscription}
              onSelectionChange={(e) => {
                setSelectedSubscription(e.value as Subscription);
                setIsModalOpen(true);
              }}
              filters={filters}
              globalFilterFields={["customer.name", "address", "phone"]}
              header={
                <TableHeader
                  header="Subskrypcje"
                  showAddButton={true}
                  onClickAdd={() => {
                    handleAddNewSubscription();
                    setIsModalOpen(true);
                  }}
                />
              }
            >
              <Column
                field="customerId"
                header="Klient"
                sortable
                body={(x) => `${x.customer?.name}`}
              />
              <Column field="product.name" header="Produkt" sortable />
              <Column
                field="startDate"
                header="Okres"
                sortable
                body={(x) =>
                  `${format(new Date(x.startDate), "dd-MM-yyyy")} +${
                    x.isMonthly ? "m" : x.daysCount
                  }`
                }
              />
            </DataTable>
          </div>
        </div>
      )}
    </>
  );
}
