import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { CustomerHoliday } from "../queries/models/customer-holiday.model";

class CustomerHolidaysService extends BaseService {

    async getAllActive(): Promise<CustomerHoliday[]> {
        const url = `${this.endpointPath}/active`;
        return this.http.get<CustomerHoliday[]>(url).then((response: any) => response.data);
    }

    async add(request: CustomerHoliday) {
        const url = `${this.endpointPath}/create`;
        return this.http.post<CustomerHoliday>(url, request).then((response: any) => response.data);
    }
    async edit(request: CustomerHoliday) {
        const url = `${this.endpointPath}/update`;
        return this.http.put<CustomerHoliday>(url, request).then((response: any) => response.data);
    }

    async delete(id: number) {
        const url = `${this.endpointPath}/delete/${id}`;
        return this.http.delete(url).then((response: any) => response.data);
    }
}

export const customerHolidaysService = new CustomerHolidaysService(API_ENDPOINTS.CUSTOMER_HOLIDAYS);