import {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useUsersQuery } from "../../queries/users.query";
import { useDeleteUserMutation } from "../../queries/users.query";
import { useAddUserMutation } from "../../queries/users.query";
import { useEditUserMutation } from "../../queries/users.query";
import { Loader } from "../../components/ui/Loader";
import { DataTable } from "primereact/datatable";
import { TableHeader } from "../../components/ui/table-header";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { User } from "../../queries/models/user.model";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import { UserForm } from "./UserForm";
import { CreateUserRequest } from "../../queries/models/create-user-request.model";
import { UpdateUserRequest } from "../../queries/models/update-user-request";
import { AddOrEditUser } from "../../queries/models/form-user.model";
import Enumerable from "linq";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { FilterMatchMode } from "primereact/api";
import { useParams } from "react-router-dom";

export function Users() {
  const queryClient = useQueryClient();
  const usersQuery = useUsersQuery();
  const addUserMutation = useAddUserMutation();
  const editUserMutation = useEditUserMutation();
  const deleteUserMutation = useDeleteUserMutation();
  const toast = useToast();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const params = useParams();
  const queryId = useMemo(
    () => (params?.id ? Number(params.id) : undefined),
    [params]
  );
  useEffect(() => {
    if (queryId) {
      const rider = usersQuery.data?.find((x) => x.id === queryId);

      if (rider) {
        setSelectedUser(rider);
        setIsModalOpen(true);
      }
    }
  }, [queryId, usersQuery.data]);

  const handleGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      const value = e.target.value;
      let _filters = filters;
      _filters.global.value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );

  const handleAddNewUser = () => {
    setSelectedUser({
      username: "",
    } as User);
  };

  const handleSaveUser = (data: AddOrEditUser) => {
    return data?.id ? editExistingUser(data) : saveNewUser(data);
  };

  const saveNewUser = (data: AddOrEditUser) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedUser(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Save success",
        });
        await queryClient.invalidateQueries("users");
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: CreateUserRequest = {
      name: data.name,
      shortName: data.shortName,
      phone: data.phone,
      email: data.email,
      password: data.password ?? "",
      username: data.username,
      isAdmin: data.isAdmin,
    };

    return addUserMutation.mutateAsync(request, mutateOptions);
  };

  const editExistingUser = (data: AddOrEditUser) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedUser(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Edit success",
        });
        await queryClient.invalidateQueries("users");
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: UpdateUserRequest = {
      id: data.id,
      name: data.name,
      shortName: data.shortName,
      phone: data.phone,
      email: data.email,
      password: data.password ?? "",
      username: data.username,
      isAdmin: data.isAdmin,
    };

    return editUserMutation.mutateAsync(request, mutateOptions);
  };

  const mobileUserFormModalProps: CustomModalProps = {
    header: selectedUser?.id ? selectedUser.username : "Add new user",
    onClose: () => {
      setSelectedUser(undefined);
      setIsModalOpen(false);
    },
    isOpen: isModalOpen,
    body: (
      <UserForm
        user={selectedUser ?? ({} as User)}
        onSave={handleSaveUser}
        onCancel={() => {
          setSelectedUser(undefined);
          setIsModalOpen(false);
        }}
        onDelete={() => setIsDeleteConfirmationModalOpen(true)}
        isSubmitting={isSubmitting}
      />
    ),
  };

  const handleDeleteUser = useCallback(() => {
    if (selectedUser) {
      deleteUserMutation.mutateAsync(selectedUser.id, {
        onSuccess: async () => {
          await queryClient.invalidateQueries("users");
          toast.current?.show({
            severity: "success",
            detail: "User successfully deleted",
          });
          setSelectedUser(undefined);
          setIsDeleteConfirmationModalOpen(false);
          setIsModalOpen(false);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error,
          });
        },
      });
    }
  }, [deleteUserMutation, queryClient, selectedUser, toast]);

  const mobileModalConfirmationFormProps: CustomModalProps = {
    header: "Confirmation",
    body: "Delete this user?",
    height: "160px",
    width: "50%",
    isOpen: isDeleteConfirmationModalOpen,
    confirmation: true,
    onClose: () => setIsDeleteConfirmationModalOpen(false),
    centered: true,
    justified: true,
    onConfirm: handleDeleteUser,
  };

  return (
    <>
      {usersQuery.isLoading ? (
        <div className="h-full flex align-items-center">
          <Loader type="spinner" />
        </div>
      ) : (
        <div className="h-full">
          {!!mobileUserFormModalProps && (
            <CustomModal {...mobileUserFormModalProps} />
          )}
          {!!mobileModalConfirmationFormProps && (
            <CustomModal {...mobileModalConfirmationFormProps} />
          )}
          <div className="p-1 h-3rem">
            <InputText
              className="w-full"
              placeholder="Filter"
              value={globalFilterValue}
              onChange={handleGlobalFilterChange}
            />
          </div>

          <div style={{ height: "calc(100% - 3rem)" }}>
            <DataTable
              value={usersQuery.data}
              resizableColumns={true}
              scrollable={true}
              scrollHeight="flex"
              selectionMode="single"
              selection={selectedUser}
              onSelectionChange={(e) => {
                setSelectedUser(e.value as User);
                setIsModalOpen(true);
              }}
              filters={filters}
              globalFilterFields={["username", "phone"]}
              header={
                <TableHeader
                  header="Users"
                  showAddButton={true}
                  onClickAdd={() => {
                    handleAddNewUser();
                    setIsModalOpen(true);
                  }}
                />
              }
            >
              <Column field="username" header="Username" sortable />
              <Column field="phone" header="Contact phone" sortable />
            </DataTable>
          </div>
        </div>
      )}
    </>
  );
}
