import { TabPanel, TabView } from "primereact/tabview";
import { Products } from "../Products/ProductsPage";
import { Holidays } from "../Holidays/HolidayPage";
import { ProductVariants } from "../ProductsVariants/ProductsVariantsPage";

export function VariablesTabs() {
  return (
    <>
      <TabView>
        <TabPanel header="Urlopy">
          <Holidays />
        </TabPanel>
        <TabPanel header="Diety">
          <Products />
        </TabPanel>
        <TabPanel header="Uwagi do diet">
          <ProductVariants />
        </TabPanel>
      </TabView>
    </>
  );
}
