import { useCallback, useEffect, useMemo, useState } from "react";
import { Loader } from "../../components/ui/Loader";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import { useCustomersQuery } from "../../queries/customers.query";
import { useSaveDeliveryOrderMutation } from "../../queries/deliveries.query";
import { SaveDeliveryOrderRequest } from "../../queries/models/save-delivery-order.request";
import { Customer } from "../../queries/models/customer.model";
import Enumerable from "linq";
import { Dropdown } from "primereact/dropdown";
import { useActiveUsersAsSelectOptionQuery } from "../../queries/users.query";
import { Button } from "primereact/button";
import { CustomerAddress } from "../../queries/models/customer-address.model";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { set } from "date-fns";

export function DrivingOrder() {
  const queryClient = useQueryClient();
  const customersQuery = useCustomersQuery();
  const driverOptionsQuery = useActiveUsersAsSelectOptionQuery();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const saveDeliveryOrderMutation = useSaveDeliveryOrderMutation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [orderdCustomers, setOrderedCustomers] = useState<CustomerAddress[]>(
    []
  );
  const [selectedDriverId, setSelectedDriverId] = useState<number>();
  const [selectedIndex, setSelectedIndex] = useState<number>();

  useEffect(() => {
    if (customersQuery.data && selectedDriverId) {
      const result =
        Enumerable.from(customersQuery.data)
          .where((x) => x.defaultDriverId === selectedDriverId)
          .selectMany((x) => x.addresses ?? [])
          .orderBy((x) => x.deliverySequence)
          .toArray() ?? [];

      setOrderedCustomers(result);
    }
  }, [customersQuery.data, selectedDriverId]);

  const handleSetOrder = useCallback(() => {
    setIsSubmitting(true);

    const mutateOptions = {
      onSuccess: async () => {
        toast.current?.show({
          severity: "success",
          detail: "Zapisane!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
      },
    };

    const request: SaveDeliveryOrderRequest = {
      items: orderdCustomers.map((x) => ({
        customerAddressId: x.id!,
        sequence: orderdCustomers.indexOf(x) + 1,
      })),
    };

    return saveDeliveryOrderMutation.mutateAsync(request, mutateOptions);
  }, [orderdCustomers, queryClient, saveDeliveryOrderMutation, toast]);

  const disabledUp = useCallback(
    (item: CustomerAddress) => {
      return orderdCustomers.indexOf(item) === 0;
    },
    [orderdCustomers]
  );

  const disabledDown = useCallback(
    (item: CustomerAddress) => {
      return orderdCustomers.indexOf(item) === orderdCustomers.length - 1;
    },
    [orderdCustomers]
  );

  const onUp = useCallback(
    (item: CustomerAddress) => {
      const index = orderdCustomers.indexOf(item);
      const newOrder = [...orderdCustomers];
      newOrder.splice(index, 1);
      newOrder.splice(index - 1, 0, item);
      setOrderedCustomers(newOrder);
    },
    [orderdCustomers]
  );

  const onDown = useCallback(
    (item: CustomerAddress) => {
      const index = orderdCustomers.indexOf(item);
      const newOrder = [...orderdCustomers];
      newOrder.splice(index, 1);
      newOrder.splice(index + 1, 0, item);
      setOrderedCustomers(newOrder);
    },
    [orderdCustomers]
  );

  const onSwap = useCallback(
    (fromIndex: number, toIndex: number) => {
      const newOrder = [...orderdCustomers];
      const [removed] = newOrder.splice(fromIndex, 1);
      newOrder.splice(toIndex, 0, removed);
      setOrderedCustomers(newOrder);
    },
    [orderdCustomers]
  );

  const destinationModalProps = useMemo(() => {
    const customerAddress = orderdCustomers[selectedIndex ?? 0];

    const title =
      customerAddress?.customer?.name + " " + customerAddress?.address;

    return {
      header: title,
      onClose: () => {
        setIsModalOpen(false);
      },
      width: "100%",
      isOpen: isModalOpen,
      body: (
        <div className="flex flex-column w-full">
          {orderdCustomers.map((customerAddress, i) => (
            <div
              key={"internal" + customerAddress.id}
              className="w-full flex flex-row shadow-1 p-1 text-center justify-content-center text-2xl"
              onClick={() => {
                onSwap(selectedIndex!, i);
                setIsModalOpen(false);
                setSelectedIndex(undefined);
              }}
            >
              {i + 1}
            </div>
          ))}
        </div>
      ),
    } as CustomModalProps;
  }, [isModalOpen, onSwap, orderdCustomers, selectedIndex]);

  return (
    <>
      {customersQuery.isLoading ? (
        <div className="h-full flex align-items-center">
          <Loader type="spinner" />
        </div>
      ) : (
        <div className="h-full w-full">
          {!!destinationModalProps && (
            <CustomModal {...destinationModalProps} />
          )}
          <div className="w-full flex flex-row">
            <div className="w-full p-2">
              <Dropdown
                value={selectedDriverId}
                options={driverOptionsQuery.data ?? []}
                optionLabel="label"
                className="w-full"
                placeholder="Wybierz kierowcę"
                onChange={(e) => setSelectedDriverId(e.value)}
              />
            </div>
            <div className="p-2">
              <Button
                label="Zapisz"
                onClick={handleSetOrder}
                loading={isSubmitting}
              />
            </div>
          </div>

          <div className="">
            {orderdCustomers.map((customerAddress, i) => (
              <div
                key={customerAddress.id}
                className="w-full flex flex-row shadow-1 p-1"
              >
                <div className="flex flex-column">
                  <Button
                    icon="pi pi-arrow-up"
                    severity="secondary"
                    aria-label="Bookmark"
                    className="h-full"
                    disabled={disabledUp(customerAddress)}
                    onClick={() => onUp(customerAddress)}
                  />
                  <Button
                    icon="pi pi-arrow-down"
                    severity="secondary"
                    aria-label="Bookmark"
                    className="h-full"
                    disabled={disabledDown(customerAddress)}
                    onClick={() => onDown(customerAddress)}
                  />
                </div>
                <div className="w-full flex flex-column px-2 align-self-center">
                  <div className="text-xl">
                    {customerAddress.customer?.name}
                  </div>
                  <div className="text-xl font-italic">
                    {customerAddress.address}
                  </div>
                </div>
                <div
                  className=" align-self-center text-2xl font-bold border-1 p-1 border-400 shadow-1 cursor-pointer"
                  onClick={() => {
                    setSelectedIndex(i);
                    setIsModalOpen(true);
                  }}
                >
                  #{i + 1}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
