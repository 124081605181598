import { useCallback, useMemo } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { classNames } from "primereact/utils";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import { useToast } from "../../components/ui/toast-context-provider";
import { Holiday } from "../../queries/models/holiday.model";
import FormikInputTextarea from "../../components/ui/formik/FormikInputTextarea";
import FormikInputNumber from "../../components/ui/formik/FormikInputNumber";
import FormikCalendar from "../../components/ui/formik/FormikCalendar";

export interface HolidayEditFormProps {
  holiday: Holiday;
  isSubmitting?: boolean;
  onSave: (form: Holiday) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
}

export function HolidayForm({
  holiday,
  onSave,
  onCancel,
  onDelete,
}: HolidayEditFormProps) {
  const lg = false;
  const toast = useToast();
  const hasWriteAccess = true;

  const initialValues: Holiday = {
    id: holiday?.id ?? 0,
    name: holiday?.name ?? "",
    date: holiday?.date ?? new Date(),
  };
  const validationSchema = Yup.object({
    name: Yup.string().min(3, "Podaj minimum 3 znaki").required("Required"),
    date: Yup.date().required(),
  });

  const onSubmit = useCallback(
    (values: Holiday) => {
      return onSave(values);
    },
    [onSave]
  );

  return (
    <div className="p-2 h-full w-full">
      {true && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form
              autoComplete="off"
              className={classNames(
                "flex",
                lg ? "flex-column" : " flex-column-reverse"
              )}
            >
              <EditContentHeader
                header={holiday?.id ? holiday.name : "Dodaj nowego konia"}
                showDeleteButton={hasWriteAccess && !!holiday.id}
                saveButtonDisabled={
                  !hasWriteAccess || !formik.dirty || !formik.isValid
                }
                onSaveClick={async () => {
                  if (!formik.isValid) {
                    toast.current?.show({
                      severity: "error",
                      detail: "Form invalid",
                    });
                    return;
                  }
                  return formik.submitForm();
                }}
                onCancelClick={onCancel}
                onDeleteClick={onDelete}
              />

              <div className="formgrid grid">
                <div className="field col-12">
                  <FormikCalendar
                    label="Data"
                    name="date"
                    validationSchema={validationSchema}
                    isIndependent
                    locale="pl"
                    dateFormat="DD dd MM yy"
                    showTime={false}
                  />
                </div>
                <div className="field col-12">
                  <FormikInputText
                    label="Nazwa"
                    name="name"
                    validationSchema={validationSchema}
                    isIndependent
                    autoComplete="off"
                    disabled={!hasWriteAccess}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
