import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faUser } from "@fortawesome/free-solid-svg-icons";
import { authService } from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { CustomModal, CustomModalProps } from "../ui/MobileModal/custom-modal";
import logo from "../../assets/icons/logo-white.svg";
import { Tooltip } from "primereact/tooltip";
import { useGetAppSettingsQuery } from "../../queries/appsettings.query";

export function TopBarNavigation() {
  const navigate = useNavigate();
  const [isLogoutConfirmationModalOpen, setIsLogoutConfirmationModalOpen] =
    useState<boolean>(false);

  const appSettingsQuery = useGetAppSettingsQuery();

  const logoutConfirmationModalProps: CustomModalProps = useMemo(() => {
    return {
      header: "Potwierdzenie wylogowania",
      body: "Prosze potwierdz, że chcesz się wylogować?",
      height: "160px",
      isOpen: isLogoutConfirmationModalOpen,
      confirmation: true,
      centered: true,
      justified: true,
      onClose: () => setIsLogoutConfirmationModalOpen(false),
      onConfirm: () => {
        setIsLogoutConfirmationModalOpen(false);
        authService.logout();
        navigate(-1);
      },
    };
  }, [navigate, isLogoutConfirmationModalOpen]);

  const logedUserName = authService.getLoggedUser()?.username;

  const toolTipOffset = (logedUserName?.length ?? 0) * -8.5 - 25;

  return (
    <>
      <div className="bg-primary flex" style={{ height: "50px" }}>
        <Tooltip
          target=".username"
          position="mouse"
          mouseTrackLeft={toolTipOffset}
          mouseTrack={true}
        />
        <CustomModal {...logoutConfirmationModalProps} />
        <div className="flex align-items-center w-8">
          <div className="flex pl-3">
            <div className="justify-content-center flex"></div>
            <div className="text-4xl font-bold text-white no-underline ml-2">
              {appSettingsQuery?.data?.appName ?? "Dąbrowiannka"}
            </div>
          </div>
        </div>
        <div className="flex w-5 flex-row justify-content-end px-1 w-full align-items-center">
          <span className="pr-1 username" data-pr-tooltip={logedUserName}>
            <FontAwesomeIcon
              icon={faUser}
              className="mr-2 p-2 border-circle cursor-pointer"
              style={{
                backgroundColor: "white",
                color: "#00796B",
                width: "20px",
                height: "20px",
              }}
            />
          </span>
          <span onClick={() => setIsLogoutConfirmationModalOpen(true)}>
            <FontAwesomeIcon
              icon={faPowerOff}
              className="mr-2 p-2 border-circle cursor-pointer"
              style={{
                backgroundColor: "white",
                color: "#00796B",
                width: "20px",
                height: "20px",
              }}
            />
          </span>
        </div>
      </div>
    </>
  );
}
