import { UseQueryOptions, useMutation, useQuery } from 'react-query';
import { deliveriesService } from '../services/deliveries.service';
import { Delivery } from './models/delivery.model';
import { DeliveryInfo } from './models/delivery-info,model';
import { SaveDeliveryOrderRequest } from './models/save-delivery-order.request';

export function useDailyDeliveriesQuery(date: Date) {
    const queryOptions: UseQueryOptions<Delivery[]> = {
        enabled: !(!date)
    }

    return useQuery<Delivery[]>(
        ["deliveries", date],
        () => deliveriesService.getDailyDeliveries(date),
        queryOptions
    )
}

export function useUpdateDeliveryInfoMutation() {
    return useMutation((request: DeliveryInfo) => deliveriesService.update(request));
}

export function useUpdateDeliveryInfosMutation() {
    return useMutation((request: DeliveryInfo[]) => deliveriesService.updateMany(request));
}

export function useSaveDeliveryOrderMutation() {
    return useMutation((request: SaveDeliveryOrderRequest) => deliveriesService.saveDeliveryOrder(request));
}