import { useCallback, useMemo, useState } from "react";
import { CustomerHoliday } from "../../queries/models/customer-holiday.model";
import { useToast } from "../../components/ui/toast-context-provider";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { classNames } from "primereact/utils";
import FormikCalendar from "../../components/ui/formik/FormikCalendar";
import { Button } from "primereact/button";
import {
  useAddCustomerHolidayMutation,
  useDeleteCustomerHolidayMutation,
  useEditCustomerHolidayMutation,
} from "../../queries/customer.holidays.query";
import { useQueryClient } from "react-query";

export interface CustomerHolidayFormProps {
  customerHoliday: CustomerHoliday;
}

export function CustomerHolidayForm({
  customerHoliday,
}: CustomerHolidayFormProps) {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const addMutation = useAddCustomerHolidayMutation();
  const editMutation = useEditCustomerHolidayMutation();
  const deleteMutation = useDeleteCustomerHolidayMutation();

  const initialValues: CustomerHoliday = {
    id: customerHoliday?.id ?? 0,
    dateFrom: customerHoliday?.dateFrom,
    dateTo: customerHoliday?.dateTo,
    customerId: customerHoliday?.customerId,
  };
  const validationSchema = Yup.object({
    dateFrom: Yup.date().required(),
    dateTo: Yup.date().required(),
    customerId: Yup.number().required(),
  });

  const saveNewHoliday = useCallback(
    (data: CustomerHoliday) => {
      setIsSubmitting(true);
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: "Zapisane!",
          });
          await queryClient.invalidateQueries();
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error,
          });
        },
        onSettled: () => {
          setIsSubmitting(false);
        },
      };

      return addMutation.mutateAsync(data, mutateOptions);
    },
    [addMutation, queryClient, toast]
  );

  const editExistingHoliday = useCallback(
    (data: CustomerHoliday) => {
      setIsSubmitting(true);
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: "Zapisane!",
          });
          await queryClient.invalidateQueries();
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error,
          });
        },
        onSettled: () => {
          setIsSubmitting(false);
        },
      };

      return editMutation.mutateAsync(data, mutateOptions);
    },
    [editMutation, queryClient, toast]
  );

  const deleteExustingHoliday = useCallback(
    (data: CustomerHoliday) => {
      deleteMutation.mutateAsync(data.id!, {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          toast.current?.show({
            severity: "success",
            detail: "Zapisane!",
          });
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error,
          });
        },
      });
    },
    [deleteMutation, queryClient, toast]
  );

  const onSubmit = useCallback(
    (data: CustomerHoliday, formik: any) => {
      if (data?.id) {
        return editExistingHoliday(data);
      } else {
        formik.resetForm();
        return saveNewHoliday(data);
      }
    },
    [editExistingHoliday, saveNewHoliday]
  );

  return (
    <div className="p- h-full w-full">
      {true && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form autoComplete="off" className={classNames("flex flex-row")}>
              <div className="field w-full pl-0 pr-1">
                <FormikCalendar
                  label="Od"
                  name="dateFrom"
                  validationSchema={validationSchema}
                  isIndependent
                  dateFormat="dd/mm/yy"
                  showTime={false}
                  showLabel={false}
                />
              </div>
              <div className="field w-full px-1">
                <FormikCalendar
                  label="Do"
                  name="dateTo"
                  validationSchema={validationSchema}
                  isIndependent
                  dateFormat="dd/mm/yy"
                  showTime={false}
                  showLabel={false}
                />
              </div>
              <div className="field">
                {!customerHoliday.id && (
                  <Button
                    icon="pi pi-plus"
                    severity="success"
                    aria-label="Bookmark"
                    className="h-full"
                    onClick={() => formik.submitForm()}
                  />
                )}

                {customerHoliday.id && (
                  <div className="flex flex-row justify-content-between">
                    <Button
                      icon="pi pi-save"
                      severity="success"
                      aria-label="Bookmark"
                      className="h-full"
                      onClick={() => formik.submitForm()}
                    />
                    <Button
                      icon="pi pi-times"
                      severity="danger"
                      aria-label="Bookmark"
                      className="h-full"
                      onClick={() => deleteExustingHoliday(customerHoliday)}
                    />
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
