import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { Customer } from "../queries/models/customer.model";
import { Delivery } from "../queries/models/delivery.model";
import { DeliveryInfo } from "../queries/models/delivery-info,model";
import { SaveDeliveryOrderRequest } from "../queries/models/save-delivery-order.request";
import { MonthlyDeliveriesCountRequest } from "../queries/models/monthly-deliveries-count.request";

class DeliveriesService extends BaseService {
    async getDailyDeliveries(date: Date): Promise<Delivery[]> {
        const url = `${this.endpointPath}/${date.toISOString()}`;
        return this.http.get<Customer[]>(url).then((response: any) => response.data);
    }
    async getMonthlyDeliveriesCount(request: MonthlyDeliveriesCountRequest): Promise<number> {
        const url = `${this.endpointPath}/monthly-deliveries-count`;
        return this.http.put<any>(url, request).then((response: any) => response.data);
    }

    async update(request: DeliveryInfo) {
        const url = `${this.endpointPath}`;
        return this.http.put<DeliveryInfo>(url, request).then((response: any) => response.data);
    }

    async updateMany(request: DeliveryInfo[]) {
        const url = `${this.endpointPath}/many`;
        return this.http.put<DeliveryInfo>(url, request).then((response: any) => response.data);
    }

    async saveDeliveryOrder(request: SaveDeliveryOrderRequest) {
        const url = `${this.endpointPath}/save-delivery-order`;
        return this.http.put<SaveDeliveryOrderRequest>(url, request).then((response: any) => response.data);
    }
}

export const deliveriesService = new DeliveriesService(API_ENDPOINTS.DELIVERIES);