import { Checkbox } from "primereact/checkbox";
import { Customer } from "../../queries/models/customer.model";
import { Delivery } from "../../queries/models/delivery.model";
import { classNames } from "primereact/utils";
import { useCallback, useMemo } from "react";
import { use } from "echarts";

export interface PlanRowProps {
  customer: Customer;
  deliveries: Delivery[];
  onDeliverySelected: (delivery: Delivery) => void;
  onDeliveryUpdated: (delivery: Delivery) => void;
}

export function PlanRow({
  customer,
  deliveries,
  onDeliverySelected,
  onDeliveryUpdated,
}: PlanRowProps) {
  const hasMissedDeliveries = useMemo(() => {
    return deliveries.some((x) => x.isMissed);
  }, [deliveries]);

  const getDeliveryMeals = useCallback((delivery: Delivery) => {
    if (
      delivery.subscription.forBreakfast &&
      delivery.subscription.forSecondBreakfast &&
      delivery.subscription.forLunch &&
      delivery.subscription.forAfternoonTea &&
      delivery.subscription.forDinner
    ) {
      return "Wszystkie";
    }

    let meals = [];
    if (delivery.subscription.forBreakfast) {
      meals.push("ŚN");
    }
    if (delivery.subscription.forSecondBreakfast) {
      meals.push("2ŚN");
    }
    if (delivery.subscription.forLunch) {
      meals.push("OBIAD");
    }
    if (delivery.subscription.forAfternoonTea) {
      meals.push("PODW");
    }
    if (delivery.subscription.forDinner) {
      meals.push("KOL");
    }

    return meals.join(", ");
  }, []);

  const openGoogleMaps = useCallback(() => {
    const formattedAddress = encodeURIComponent(customer.address);
    const url = `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;
    window.open(url, "_blank");
  }, [customer.address]);

  const openPhoneCall = useCallback(() => {
    window.open(`tel:${customer.phone}`);
  }, [customer.phone]);

  return (
    <div
      className={classNames("flex w-full flex-column shadow-1 mt-1 p-2", {
        "bg-red-100": hasMissedDeliveries,
      })}
    >
      <div className="flex flex-row w-full justify-content-space-between">
        <div className="text-xl font-bold">
          #{customer.deliverySequence} {customer.name}
        </div>
        <div className="text-xl" onClick={openPhoneCall}>
          {customer.phone}
        </div>
      </div>
      <div
        className="flex flex-row w-full justify-content-space-between"
        onClick={openGoogleMaps}
      >
        <div className="text-xl font-italic">{customer.address}</div>
      </div>
      <div className="w-full flex flex-column">
        {deliveries.map((delivery, i) => (
          <div
            key={i}
            className={classNames("flex flex-row text-xl", {
              "line-through": delivery.isDelivered,
              "text-red-800": delivery.isMissed,
            })}
          >
            <Checkbox
              className="flex align-content-center m-1"
              onChange={(e) => {
                delivery.isDelivered = e.checked as boolean;
                onDeliveryUpdated(delivery);
              }}
              checked={delivery.isDelivered}
            ></Checkbox>
            <div
              className="pl-2 w-full"
              onClick={(x) => onDeliverySelected(delivery)}
            >
              {delivery.subscription.product?.name}
              {delivery.subscription.customer?.productVariant?.name
                ? ` - ${delivery.subscription.customer?.productVariant?.name} `
                : " "}
              {getDeliveryMeals(delivery)} ({delivery.deliveryIndex}/
              {delivery.deliveriesCount})
            </div>

            {!delivery.subscription.isPaid && (
              <div
                className="flex justify-content-end font-bold text-red-800"
                onClick={(x) => onDeliverySelected(delivery)}
              >
                Opłać!
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
