import { useMutation, useQuery } from 'react-query';
import { Customer } from './models/customer.model';
import { customersService } from '../services/customers.service';

export function useCustomersQuery() {
    return useQuery<Customer[]>(["customers"], () =>
        customersService.getAllActive());
}
export function useCustomerQuery(id: number | undefined) {
    return useQuery<Customer | undefined>(
        ["customers", id],
        () => customersService.getById(id!),
        { refetchOnWindowFocus: false, enabled: id !== undefined }
    );
}

export function useActiveCustomersAsSelectOptionQuery() {
    return useQuery(
        ["customers", "customers-options"],
        () => customersService.getAllActiveAsOptions()
    )
}

export function useAddCustomerMutation() {
    return useMutation((request: Customer) => customersService.add(request));
}

export function useEditCustomerMutation() {
    return useMutation((request: Customer) => customersService.edit(request));
}

export function useDeleteCustomerMutation() {
    return useMutation((id: number) => customersService.delete(id));
}