import { useCallback, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import { EnumExtensions } from "../../utils/enum-extensions";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { classNames } from "primereact/utils";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import { useToast } from "../../components/ui/toast-context-provider";
import { Customer } from "../../queries/models/customer.model";
import FormikInputTextarea from "../../components/ui/formik/FormikInputTextarea";
import { Divider } from "primereact/divider";
import { format } from "date-fns";
import { DataTable } from "primereact/datatable";
import { TableHeader } from "../../components/ui/table-header";
import { Column } from "primereact/column";
import { Panel } from "primereact/panel";
import { Subscription } from "../../queries/models/subscription.model";
import { useNavigate } from "react-router-dom";
import { SelectItem } from "primereact/selectitem";
import FormikDropDown from "../../components/ui/formik/FormikDropdown";
import {
  useCustomerQuery,
  useCustomersQuery,
} from "../../queries/customers.query";
import FormikInputNumber from "../../components/ui/formik/FormikInputNumber";
import { TabPanel, TabView } from "primereact/tabview";
import { CustomerCalendar } from "./CustomerCalendar";
import { CustomerHolidayForm } from "./CustomerHolidayForm";
import { FormInputLabel } from "../../components/ui/formik/FormInputLabel";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { CustomerAddress } from "../../queries/models/customer-address.model";
import { Checkbox } from "primereact/checkbox";

export interface CustomerEditFormProps {
  customer: Customer;
  isSubmitting?: boolean;
  driverOptions: SelectItem[];
  productsVariantsOptions: SelectItem[];
  onSave: (form: Customer) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
}

export function CustomerForm({
  customer,
  onSave,
  onCancel,
  driverOptions,
  productsVariantsOptions,
  onDelete,
}: CustomerEditFormProps) {
  const toast = useToast();
  const hasWriteAccess = true;
  const navigate = useNavigate();

  const customerQuery = useCustomerQuery(customer?.id);

  const [newAddress, setNewAddress] = useState<any>({});

  const initialValues: Customer = {
    id: customer?.id ?? 0,
    name: customer?.name ?? "",
    address: customer?.address ?? "",
    dateCreated: customer?.dateCreated ?? new Date(),
    dateModified: customer?.dateModified ?? new Date(),
    phone: customer?.phone ?? "",
    email: customer?.email ?? "",
    lastModifiedById: customer?.lastModifiedById ?? 0,
    deliveries: customer?.deliveries ?? [],
    subscriptions: customer?.subscriptions ?? [],
    defaultDriverId: customer?.defaultDriverId ?? 0,
    defaultDriver: customer?.defaultDriver ?? undefined,
    productVariantId: customer?.productVariantId ?? undefined,
    deliverySequence: customer?.deliverySequence ?? 0,
    addresses: customer?.addresses ?? [],
    comment: customer?.comment ?? "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().min(3, "Podaj minimum 3 znaki").required("Required"),
    comment: Yup.string().optional(),
    phone: Yup.string().required(),
    email: Yup.string().email().optional().nullable(),
    deliveries: Yup.array().optional().nullable(),
    subscriptions: Yup.array().optional().nullable(),
    allergenInfo: Yup.string().optional().nullable(),
    deliverySequence: Yup.number().required(),
    productVariantId: Yup.number().optional().nullable(),
    addresses: Yup.array().required(),
  });

  const onSubmit = useCallback(
    (values: Customer) => {
      return onSave(values);
    },
    [onSave]
  );

  const handleAddNewSubscription = useCallback(() => {
    navigate(`/app/subscriptions?customerId=${customer.id}`);
  }, [customer.id, navigate]);

  const handleEditSubscription = useCallback(
    (subscription: Subscription) => {
      navigate(`/app/subscriptions?id=${subscription.id}`);
    },
    [navigate]
  );

  return (
    <div className="h-full w-full">
      <TabView>
        <TabPanel header="Dane">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnChange
            validateOnMount
          >
            {(formik) => (
              <Form autoComplete="off" className="flex flex-column-reverse p-2">
                <EditContentHeader
                  header={customer?.id ? customer.name : "Dodaj nowego klienta"}
                  showDeleteButton={hasWriteAccess && !!customer.id}
                  saveButtonDisabled={
                    !hasWriteAccess || !formik.dirty || !formik.isValid
                  }
                  onSaveClick={async () => {
                    if (
                      !formik.isValid ||
                      formik.values.addresses === undefined ||
                      formik.values.addresses.length === 0
                    ) {
                      toast.current?.show({
                        severity: "error",
                        detail: "Form invalid",
                      });
                      return;
                    }
                    return formik.submitForm();
                  }}
                  onCancelClick={onCancel}
                  onDeleteClick={onDelete}
                />

                <div className="formgrid grid py-2">
                  <div className="field col-12">
                    <FormikInputText
                      label="Nazwa"
                      name="name"
                      validationSchema={validationSchema}
                      isIndependent
                      autoComplete="off"
                      disabled={!hasWriteAccess}
                    />
                  </div>
                  <div className="field col-6 md:col-6">
                    <FormikInputText
                      label="Telefon"
                      name="phone"
                      validationSchema={validationSchema}
                      isIndependent
                      autoComplete="off"
                      disabled={!hasWriteAccess}
                    />
                  </div>
                  <div className="field col-6 md:col-6">
                    <FormikInputText
                      label="Email"
                      name="email"
                      validationSchema={validationSchema}
                      isIndependent
                      autoComplete="off"
                      disabled={!hasWriteAccess}
                    />
                  </div>

                  <div className="field col-12">
                    <FormInputLabel
                      nameFor={"addresses"}
                      validationSchema={validationSchema}
                      forceIsRequired={false}
                    >
                      {"Adresy"}
                    </FormInputLabel>
                    {formik.values.addresses?.map((address, index) => (
                      <div
                        className="w-full flex flex-column"
                        key={`$${address.isActive}${address.defaultDriverId}`}
                      >
                        <div className="flex flex-row align-items-center">
                          <div className="flex mr-2">
                            <Checkbox
                              checked={address.isActive ?? false}
                              disabled={formik.values.addresses.length <= 1}
                              onChange={(e) => {
                                const newArray = [
                                  ...(formik.values.addresses ?? []),
                                ];

                                const index = newArray.findIndex(
                                  (x) =>
                                    x.defaultDriverId ===
                                      address.defaultDriverId &&
                                    x.address === address.address
                                );

                                newArray.forEach((x) => (x.isActive = false));
                                newArray[index].isActive = e.checked as boolean;

                                formik.setFieldValue("addresses", newArray);
                              }}
                            />
                          </div>
                          <div className="w-8 flex h-full">
                            <FormikDropDown
                              showLabel={false}
                              className="w-full"
                              label="Kierowca"
                              name={`addresses.${index}.defaultDriverId`}
                              validationSchema={validationSchema}
                              isIndependent
                              options={driverOptions}
                              disabled={!hasWriteAccess}
                              filter
                            />
                          </div>
                          <div className="w-full p-1">
                            <FormikInputTextarea
                              showLabel={false}
                              label="Adres"
                              name={`addresses.${index}.address`}
                              validationSchema={validationSchema}
                              isIndependent
                              autoComplete="off"
                              disabled={!hasWriteAccess}
                            />
                          </div>
                          <div className="p-1">
                            <Button
                              className="bg-red-600 border-red-500"
                              type="button"
                              disabled={
                                !address.defaultDriverId ||
                                !address.address ||
                                formik.values.addresses.length <= 1
                              }
                              icon="pi pi-trash"
                              onClick={() => {
                                const newArray = [
                                  ...(formik.values.addresses ?? []),
                                ];

                                const index = newArray.findIndex(
                                  (x) =>
                                    x.address === address.address &&
                                    x.defaultDriverId ===
                                      address.defaultDriverId
                                );

                                newArray.splice(index, 1);

                                if (
                                  newArray.findIndex((x) => x.isActive) === -1
                                ) {
                                  newArray[0].isActive = true;
                                }

                                formik.setFieldValue("addresses", newArray);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="flex flex-row align-items-center">
                      <div className="w-8 flex h-full">
                        <Dropdown
                          className="w-full"
                          type="text"
                          options={driverOptions}
                          placeholder={"Kierowca"}
                          value={newAddress.defaultDriverId}
                          onChange={(e) => {
                            setNewAddress({
                              defaultDriverId: e.target.value,
                              address: newAddress.address,
                            });
                          }}
                        />
                      </div>
                      <div className="w-full p-1">
                        <InputTextarea
                          className="w-full"
                          placeholder={"Address"}
                          value={newAddress.address ?? ""}
                          onChange={(e) => {
                            setNewAddress({
                              defaultDriverId: newAddress.defaultDriverId,
                              address: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="p-1">
                        <Button
                          className="settings-button"
                          type="button"
                          disabled={
                            !newAddress.defaultDriverId || !newAddress.address
                          }
                          icon="pi pi-plus"
                          onClick={() => {
                            const newArray = [
                              ...(formik.values.addresses ?? []),
                            ];
                            newArray.forEach((x) => (x.isActive = false));
                            newArray.push({
                              defaultDriverId: newAddress.defaultDriverId,
                              address: newAddress.address,
                              customerId: formik.values.id,
                              isActive: true,
                            });

                            formik.setFieldValue("addresses", newArray);
                            setNewAddress({});
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="field col-12">
                    <FormikInputText
                      label="Komentarz"
                      name="comment"
                      validationSchema={validationSchema}
                      isIndependent
                      disabled={!hasWriteAccess}
                    />
                  </div>

                  <div className="field col-12">
                    <FormikDropDown
                      label="Uwagi do diety"
                      name="productVariantId"
                      validationSchema={validationSchema}
                      isIndependent
                      options={productsVariantsOptions}
                      disabled={!hasWriteAccess}
                      showClear={true}
                      filter
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </TabPanel>

        {customer?.id && (
          <TabPanel header="Dostawy">
            <CustomerCalendar customer={customerQuery.data} />
            <Divider />

            <div className="field col-12">
              <div className="h-full flex flex-column">
                <label className="font-medium m-1 w-full">
                  Przerwy w dostawie
                </label>

                <CustomerHolidayForm
                  customerHoliday={{
                    customerId: customer.id,
                  }}
                />
                {(customerQuery.data?.holidays ?? []).map((holiday, i) => (
                  <CustomerHolidayForm key={i} customerHoliday={holiday} />
                ))}
              </div>
            </div>

            <Panel header="Informacje o kliencie" className="mt-2">
              <div className="flex flex-column">
                <div className="flex flex-row">
                  <div className="text-600 w-6 text-right">Dołączył dnia: </div>
                  <div className="px-2">
                    {format(new Date(customer.dateCreated), "dd-MM-yyyy")}
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="text-600 w-6 text-right">
                    Ostatnia dostawa:{" "}
                  </div>
                  <div className="px-2">
                    {customer.lastDelivery
                      ? format(new Date(customer.lastDelivery), "dd-MM-yyyy")
                      : "Brak"}
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="text-600 w-6 text-right">
                    Koniec subskrypcji:{" "}
                  </div>
                  <div className="px-2">
                    {customer.trueEndOfSubscription
                      ? format(
                          new Date(customer.trueEndOfSubscription),
                          "dd-MM-yyyy"
                        )
                      : ""}
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="text-600 w-6 text-right">
                    Wszystko opłacone:{" "}
                  </div>
                  <div className="px-2">
                    {customer.allSubscriptionsPaid ? "tak" : "nie"}
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="text-600 w-6 text-right">
                    Pozostało dostaw:{" "}
                  </div>
                  <div className="px-2">{customer.reamingDeliveries ?? 0}</div>
                </div>
              </div>
            </Panel>
          </TabPanel>
        )}

        {customer?.id && (
          <TabPanel header="Subskrypcje" className="">
            <div className="">
              <DataTable
                value={customerQuery.data?.subscriptions ?? []}
                resizableColumns={true}
                scrollable={true}
                scrollHeight="flex"
                selectionMode="single"
                onSelectionChange={(e) => {
                  handleEditSubscription(e.value as Subscription);
                }}
                header={
                  <TableHeader
                    header="Subskrypcje"
                    showAddButton={true}
                    onClickAdd={handleAddNewSubscription}
                  />
                }
              >
                <Column
                  field="startDate"
                  header="Data"
                  sortable
                  body={(x) =>
                    `${format(new Date(x.startDate), "dd-MM-yyyy")} +${
                      x.isMonthly ? "m" : x.daysCount
                    }`
                  }
                />
                <Column
                  field="isAutoRenewal"
                  header="Auto"
                  body={(x) => (x.isAutoRenewal ? "tak" : "nie")}
                />
                <Column
                  field="isPaid"
                  header="Opłacona"
                  body={(x) => (x.isPaid ? "tak" : "nie")}
                />
              </DataTable>
            </div>
          </TabPanel>
        )}
      </TabView>
    </div>
  );
}
