import { BaseSyntheticEvent, useCallback, useMemo, useState } from "react";
import { Loader } from "../../components/ui/Loader";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import {
  useDailyDeliveriesQuery,
  useUpdateDeliveryInfoMutation,
  useUpdateDeliveryInfosMutation,
} from "../../queries/deliveries.query";
import { Delivery } from "../../queries/models/delivery.model";
import { authService } from "../../services/auth.service";
import { addDays, addHours, format, formatDistance } from "date-fns";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { Button } from "primereact/button";
import Enumerable from "linq";
import { PlanRow } from "./PlanRow";
import { Divider } from "primereact/divider";
import { PlanDeliveryForm } from "./PlanDeliveryForm";
import { DeliveryInfo } from "../../queries/models/delivery-info,model";
import { usePaySubscriptionMutation } from "../../queries/subscriptions.query";
import { ProductSummary } from "../../queries/models/product-summary.model";
import { KitchenPapersGenerator } from "./kitchen-papers-generator";
import { useActiveProductsAsSelectOptionQuery } from "../../queries/products.query";
import { MultiSelect } from "primereact/multiselect";

export function Plan() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const [selectedDate, setSelectedDate] = useState<Date>(
    new Date(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      12,
      0,
      0,
      0
    )
  );

  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);

  const deliveriesQuery = useDailyDeliveriesQuery(selectedDate);
  const updateDeliveryMutation = useUpdateDeliveryInfoMutation();
  const updateDeliveriesMutation = useUpdateDeliveryInfosMutation();
  const payMutation = usePaySubscriptionMutation();
  const productsQuery = useActiveProductsAsSelectOptionQuery();

  const [selectedDelivery, setSelectedDelivery] = useState<Delivery>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isProductSummaryModalOpen, setProductSummaryIsModalOpen] =
    useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isAdmin = useMemo(() => {
    return authService.isAdmin();
  }, []);

  const customerDeliveryGroups = useMemo(() => {
    const userId = authService.getLoggedUser()?.id;
    var filtered = isAdmin
      ? deliveriesQuery.data ?? []
      : deliveriesQuery.data?.filter(
          (delviery) =>
            delviery.subscription.customer!.defaultDriverId === userId
        ) ?? [];

    if (selectedProducts.length > 0) {
      filtered = filtered.filter((x) =>
        selectedProducts.includes(x.subscription.product?.id ?? 0)
      );
    }

    const result =
      Enumerable.from(filtered)
        .groupBy((r) => r.subscription.customerId)
        .orderBy(
          (g) => g.getSource()[0].subscription.customer?.deliverySequence
        )
        .select((g) => {
          return {
            customer: g.getSource()[0].subscription.customer!,
            deliveries: g.getSource(),
          };
        })
        .toArray() ?? [];
    return result;
  }, [isAdmin, deliveriesQuery.data, selectedProducts]);

  const productSummary = useMemo(() => {
    return (Enumerable.from(deliveriesQuery.data ?? [])
      .groupBy((r) => r.subscription.product?.name)
      .orderBy((g) => g.key())
      .select((g) => {
        return {
          label: g.key(),
          value: g.getSource().length,
          subMeals:
            Enumerable.from(g.getSource())
              .groupBy((r) => r.subscription.customer?.productVariant?.name)
              .orderBy((g) => g.key())
              .selectMany((g) => {
                let result = [];
                let labelSuffix = g.key() ? ` (${g.key()})` : "";
                let meal1Count = g
                  .getSource()
                  .filter((x) => x.subscription.forBreakfast).length;
                let meal2Count = g
                  .getSource()
                  .filter((x) => x.subscription.forSecondBreakfast).length;
                let meal3Count = g
                  .getSource()
                  .filter((x) => x.subscription.forLunch).length;
                let meal4Count = g
                  .getSource()
                  .filter((x) => x.subscription.forAfternoonTea).length;
                let meal5Count = g
                  .getSource()
                  .filter((x) => x.subscription.forDinner).length;

                if (meal1Count > 0)
                  result.push({
                    label: `Sniadanie${labelSuffix}`,
                    value: meal1Count,
                  });
                if (meal2Count > 0)
                  result.push({
                    label: `II Sniadanie${labelSuffix}`,
                    value: meal2Count,
                  });
                if (meal3Count > 0)
                  result.push({
                    label: `Obiad${labelSuffix}`,
                    value: meal3Count,
                  });
                if (meal4Count > 0)
                  result.push({
                    label: `Podwieczorek${labelSuffix}`,
                    value: meal4Count,
                  });
                if (meal5Count > 0)
                  result.push({
                    label: `Kolacja${labelSuffix}`,
                    value: meal5Count,
                  });

                return result;
              })
              .toArray() ?? [],
        };
      })
      .toArray() ?? []) as ProductSummary[];
  }, [deliveriesQuery.data]);

  const handleSelectedDateChange = useCallback((e: CalendarChangeEvent) => {
    setSelectedDate(addHours(new Date(e.value as string), 12));
  }, []);

  const handleSetAllDeliveriesNotDelivered = useCallback(() => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedDelivery(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Zapisane!",
        });
        await queryClient.invalidateQueries("deliveries");
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request =
      deliveriesQuery.data?.map((x) => {
        return {
          id: x.id,
          customerId: x.subscription.customerId,
          subscriptionId: x.subscriptionId,
          date: x.date,
          isDelivered: false,
          isMissed: x.isMissed,
        };
      }) ?? [];

    return updateDeliveriesMutation.mutateAsync(request, mutateOptions);
  }, [deliveriesQuery.data, queryClient, toast, updateDeliveriesMutation]);

  const handleDownloadPdf = useCallback(() => {
    const generator = new KitchenPapersGenerator();
    let result = generator.generateDoc(productSummary, selectedDate);

    result.save("kuchnia_" + format(selectedDate, "dd_MM_yyyy") + ".pdf");
  }, [productSummary, selectedDate]);

  const productSummaryModalProps: CustomModalProps = {
    header: "Zamówienia na dziś",
    height: "100%",
    width: "100%",
    onClose: () => {
      setProductSummaryIsModalOpen(false);
    },
    isOpen: isProductSummaryModalOpen,
    body: (
      <div className="w-full">
        <div className="p-2">
          <Divider />
          <Button
            label="Pobierz pdf"
            icon="pi pi-file-pdf"
            onClick={handleDownloadPdf}
          />
          <Divider />
          {productSummary.map((product) => (
            <div key={product.label} className="flex flex-column text-xl">
              <div className="flex flex-row">
                <div className="w-6 font-bold">{product.label}</div>
                <div className="w-6"></div>
              </div>
              {product.subMeals.map((subMeal) => (
                <div key={subMeal.label} className="flex flex-row">
                  <div className="w-10 flex justify-content-end">
                    {subMeal.label}
                  </div>
                  <div className="w-2 pl-3">x{subMeal.value}</div>
                </div>
              ))}
              <Divider />
            </div>
          ))}
        </div>
      </div>
    ),
  };

  const handleSaveDelivery = (data: Delivery) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedDelivery(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Zapisane!",
        });
        await queryClient.invalidateQueries("deliveries");
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: DeliveryInfo = {
      id: data.id,
      customerId: data.subscription.customerId,
      subscriptionId: data.subscriptionId,
      date: data.date,
      isDelivered: data.isDelivered,
      isMissed: data.isMissed,
    };

    return updateDeliveryMutation.mutateAsync(request, mutateOptions);
  };

  const handlePaySubscription = useCallback(
    (id: number) => {
      setIsSubmitting(true);
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: "Zapisane!",
          });
          selectedDelivery!.subscription.isPaid = true;
          await queryClient.invalidateQueries();
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error,
          });
        },
        onSettled: () => {
          setIsSubmitting(false);
        },
      };

      return payMutation.mutateAsync(id, mutateOptions);
    },
    [payMutation, queryClient, selectedDelivery, toast]
  );

  const deliveryFormModalProps: CustomModalProps = {
    header: "Opcje dostawy",
    onClose: () => {
      setSelectedDelivery(undefined);
      setIsModalOpen(false);
    },
    isOpen: isModalOpen,
    body: (
      <PlanDeliveryForm
        delivery={selectedDelivery ?? ({} as Delivery)}
        onSave={handleSaveDelivery}
        onCancel={() => {
          setSelectedDelivery(undefined);
          setIsModalOpen(false);
        }}
        isSubmitting={isSubmitting}
        onPaySubscription={handlePaySubscription}
      />
    ),
  };

  return (
    <>
      {deliveriesQuery.isLoading ? (
        <div className="h-full flex align-items-center">
          <Loader type="spinner" />
        </div>
      ) : (
        <div className="h-full overflow-hidden">
          {!!productSummaryModalProps && (
            <CustomModal {...productSummaryModalProps} />
          )}
          {!!deliveryFormModalProps && (
            <CustomModal {...deliveryFormModalProps} />
          )}
          <div className="p-2 flex" style={{ height: "50px" }}>
            <div className="w-10">
              <Calendar
                className="w-full"
                locale="pl"
                dateFormat="DD dd MM yy"
                onChange={handleSelectedDateChange}
                value={selectedDate}
              />
            </div>
            <div className="w-2 text-center">
              <Button
                icon="pi pi-star"
                rounded
                text
                severity="secondary"
                onClick={() => {
                  setProductSummaryIsModalOpen(true);
                }}
              />
            </div>
          </div>
          <div className="p-2 flex" style={{ height: "50px" }}>
            <div className="w-6 px-2">
              <Button
                className="w-full bg-white text-color-secondary border-gray-800"
                type="button"
                icon="pi pi-arrow-left"
                label="Poprzedni"
                onClick={() => {
                  setSelectedDate(addDays(selectedDate, -1));
                }}
              />
            </div>
            <div className="w-6 px-2">
              <Button
                className="w-full bg-white text-color-secondary border-gray-800"
                type="button"
                icon="pi pi-arrow-right"
                iconPos="right"
                label="Nastepny"
                onClick={() => {
                  setSelectedDate(addDays(selectedDate, 1));
                }}
              />
            </div>
          </div>

          <div className="p-3">
            <MultiSelect
              value={selectedProducts}
              onChange={(e) => setSelectedProducts(e.value)}
              options={productsQuery.data ?? []}
              display="chip"
              placeholder="Filtr diet"
              maxSelectedLabels={3}
              className="w-full"
            />
          </div>

          <div
            className="overflow-auto"
            style={{ height: "calc(100% - 180px)" }}
          >
            {customerDeliveryGroups.map((customerGroup) => (
              <PlanRow
                key={customerGroup.customer.id}
                customer={customerGroup.customer}
                deliveries={customerGroup.deliveries}
                onDeliverySelected={(x) => {
                  setSelectedDelivery(x);
                  setIsModalOpen(true);
                }}
                onDeliveryUpdated={(x) => {
                  handleSaveDelivery(x);
                }}
              />
            ))}
            <div className="m-2">
              <Button
                icon="pi pi-pencil"
                type="button"
                label={`Zresetuj checkboxy ${
                  deliveriesQuery.data?.filter((x) => x.isDelivered).length
                }/${deliveriesQuery.data?.length ?? 0}`}
                loading={isSubmitting}
                className="green-action-button w-full"
                onClick={handleSetAllDeliveriesNotDelivered}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
