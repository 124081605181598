import { Customer } from "../../queries/models/customer.model";
import { CalendarComponent } from "../../components/ui/calendar/CalendarComponent";
import { useCallback, useMemo } from "react";
import Enumerable from "linq";
import { CalendarEntry } from "../../components/ui/calendar/calendar-entry";
import { isSameDay, isWithinInterval, startOfDay } from "date-fns";
export interface CustomerCalendarProps {
  customer: Customer | undefined;
}

export function CustomerCalendar({ customer }: CustomerCalendarProps) {
  const entries = useMemo(() => {
    const result = Enumerable.from(customer?.subscriptions ?? [])
      .selectMany((x) => x.deliveries ?? [])
      .select(
        (x) =>
          ({
            date: new Date(x.date),
          } as CalendarEntry)
      )
      .toArray();
    debugger;
    return result;
  }, [customer?.subscriptions]);

  const dayColor = useCallback(
    (entries: CalendarEntry[]) => {
      if (entries.length === 0) return "text-gray-500";

      const date = entries[0].date;

      if (
        customer?.holidays?.find((x) =>
          isWithinInterval(startOfDay(date), {
            start: x.dateFrom,
            end: x.dateTo,
          } as any)
        )
      ) {
        return "text-red-600";
      }

      if (
        Enumerable.from(customer?.subscriptions ?? [])
          .selectMany((x) => x.deliveries ?? [])
          .any((x) => x.isMissed && isSameDay(x.date, date))
      ) {
        return "text-red-600";
      }

      return "text-teal-600";
    },
    [customer?.holidays, customer?.subscriptions]
  );

  return (
    <>
      {customer && (
        <div>
          <CalendarComponent
            entries={entries}
            onDatesSelected={(dates) => {}}
            onMonthSelected={(date) => {}}
            canSelectFuture={false}
            canSelectPast={false}
            showEntries={true}
            dayCustomClassName={dayColor}
          />
        </div>
      )}
    </>
  );
}
