import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { SelectItem } from "primereact/selectitem";
import { Customer } from "../queries/models/customer.model";

class CustomersService extends BaseService {

    async getAllActive(): Promise<Customer[]> {
        const url = `${this.endpointPath}/active`;
        return this.http.get<Customer[]>(url).then((response: any) => response.data);
    }

    async getById(id: number): Promise<Customer> {
        const url = `${this.endpointPath}/${id}`;
        return this.http.get<Customer[]>(url).then((response: any) => response.data);
    }

    async getAllActiveAsOptions(): Promise<SelectItem[]> {
        const url = `${this.endpointPath}/active-as-options`;
        return this.http.get<SelectItem[]>(url).then((response: any) => response.data);
    }

    async add(request: Customer) {
        const url = `${this.endpointPath}/create`;
        return this.http.post<Customer>(url, request).then((response: any) => response.data);
    }
    async edit(request: Customer) {
        const url = `${this.endpointPath}/update`;
        return this.http.put<Customer>(url, request).then((response: any) => response.data);
    }

    async delete(id: number) {
        const url = `${this.endpointPath}/delete/${id}`;
        return this.http.delete(url).then((response: any) => response.data);
    }
}

export const customersService = new CustomersService(API_ENDPOINTS.CUSTOMERS);