import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { CreateUserRequest } from "../queries/models/create-user-request.model";
import { UpdateUserRequest } from "../queries/models/update-user-request";
import { SelectItem } from "primereact/selectitem";
import { Customer } from "../queries/models/customer.model";
import { Subscription } from "../queries/models/subscription.model";

class SubscriptionsService extends BaseService {

    async getAllActive(): Promise<Subscription[]> {
        const url = `${this.endpointPath}/active`;
        return this.http.get<Customer[]>(url).then((response: any) => response.data);
    }

    async getAllActiveAsOptions(): Promise<SelectItem[]> {
        const url = `${this.endpointPath}/active-as-options`;
        return this.http.get<SelectItem[]>(url).then((response: any) => response.data);
    }

    async add(request: Subscription) {
        const url = `${this.endpointPath}/create`;
        return this.http.post<Subscription>(url, request).then((response: any) => response.data);
    }
    async edit(request: Subscription) {
        const url = `${this.endpointPath}/update`;
        return this.http.put<Subscription>(url, request).then((response: any) => response.data);
    }
    async pay(id: number) {
        const url = `${this.endpointPath}/${id}/pay`;
        return this.http.put<any>(url).then((response: any) => response.data);
    }

    async delete(id: number) {
        const url = `${this.endpointPath}/delete/${id}`;
        return this.http.delete(url).then((response: any) => response.data);
    }
}

export const subscriptionsService = new SubscriptionsService(API_ENDPOINTS.SUBSCIPTIONS);