import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { classNames } from "primereact/utils";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import { useToast } from "../../components/ui/toast-context-provider";
import FormikCalendar from "../../components/ui/formik/FormikCalendar";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Delivery } from "../../queries/models/delivery.model";
import FormikInputSwitch from "../../components/ui/formik/FormikInputSwitch";
import { usePaySubscriptionMutation } from "../../queries/subscriptions.query";
import { useQueryClient } from "react-query";
import { de } from "date-fns/locale";
import FormikInputTextarea from "../../components/ui/formik/FormikInputTextarea";

export interface PlanDeliveryFormProps {
  delivery: Delivery;
  isSubmitting?: boolean;
  onSave: (form: Delivery) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onPaySubscription: (id: number) => Promise<any>;
}

export function PlanDeliveryForm({
  delivery,
  isSubmitting,
  onSave,
  onCancel,
  onPaySubscription,
}: PlanDeliveryFormProps) {
  const lg = false;
  const toast = useToast();
  const hasWriteAccess = true;
  const navigate = useNavigate();

  const initialValues: Delivery = {
    id: delivery?.id ?? 0,
    date: delivery?.date ?? new Date(),
    deliveryIndex: delivery?.deliveryIndex ?? 0,
    isMissed: delivery?.isMissed ?? false,
    isDelivered: delivery?.isDelivered ?? false,
    subscriptionId: delivery?.subscriptionId ?? 0,
    deliveriesCount: delivery?.deliveriesCount ?? 0,

    subscription: delivery?.subscription ?? null,
    customerName: delivery?.subscription?.customer?.name ?? "",
    productName: delivery?.subscription?.product?.name ?? "",
    comment: delivery?.subscription?.customer?.comment ?? "",
  };
  const validationSchema = Yup.object({
    isMissed: Yup.boolean().optional(),
    IsDelivered: Yup.boolean().optional(),
  });

  const onSubmit = useCallback(
    (values: Delivery) => {
      return onSave(values);
    },
    [onSave]
  );

  const handleNavigateToCustomer = useCallback(
    (id: number | undefined) => {
      navigate(`/app/customers/${id}`);
    },
    [navigate]
  );

  const handleNavigateToSubscription = useCallback(
    (id: number | undefined) => {
      navigate(`/app/subscriptions?id=${id}`);
    },
    [navigate]
  );

  return (
    <div className="p-2 h-full w-full">
      {true && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form
              autoComplete="off"
              className={classNames(
                "flex",
                lg ? "flex-column" : " flex-column-reverse"
              )}
            >
              <EditContentHeader
                header={""}
                showDeleteButton={false}
                saveButtonDisabled={
                  !hasWriteAccess || !formik.dirty || !formik.isValid
                }
                onSaveClick={async () => {
                  if (!formik.isValid) {
                    toast.current?.show({
                      severity: "error",
                      detail: "Form invalid",
                    });
                    return;
                  }
                  return formik.submitForm();
                }}
                onCancelClick={onCancel}
                onDeleteClick={() => {}}
              />

              <div className="formgrid grid">
                <div className="field col-10">
                  <FormikCalendar
                    label="Data dostawy"
                    name="date"
                    validationSchema={validationSchema}
                    isIndependent
                    locale="pl"
                    dateFormat="DD dd MM yy"
                    showTime={false}
                    disabled={true}
                  />
                </div>
                <div className="flex col-2 justify-content-center align-self-center">
                  <span>
                    {delivery.deliveryIndex}/{delivery.subscription.daysCount}
                  </span>
                </div>

                <div className="field col-12 md:col-12">
                  <div className="flex">
                    <div className="col-10 p-0 m-0">
                      <FormikInputText
                        label="Klient"
                        name="customerName"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={true}
                      />
                    </div>
                    <div className="w-full align-self-end text-right">
                      <Button
                        className="settings-button"
                        type="button"
                        icon="pi pi-arrow-right"
                        onClick={() =>
                          handleNavigateToCustomer(
                            formik.values.subscription.customerId
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="field col-12 md:col-12">
                  <div className="flex">
                    <div className="col-10 p-0 m-0">
                      <FormikInputText
                        label="Subskrypcja"
                        name="subscriptionId"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={true}
                      />
                    </div>
                    <div className="w-full align-self-end text-right">
                      <Button
                        className="settings-button"
                        type="button"
                        icon="pi pi-arrow-right"
                        onClick={() =>
                          handleNavigateToSubscription(
                            formik.values.subscriptionId
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                {!delivery.subscription?.isPaid && (
                  <div className="field col-12">
                    <div className="flex">
                      <div className="col-10 p-0 m-0">
                        <Button
                          className="w-full"
                          label="Opłać subskrypcję"
                          type="button"
                          icon="pi pi-money-bill"
                          loading={isSubmitting}
                          onClick={(x) =>
                            onPaySubscription(delivery.subscriptionId)
                          }
                        />
                      </div>
                      <div className="w-full align-self-end text-right"></div>
                    </div>
                  </div>
                )}

                <div className="field col-12 md:col-12">
                  <FormikInputText
                    label="Produkt"
                    name="productName"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={true}
                  />
                </div>

                <div className="field col-12 md:col-12">
                  <FormikInputTextarea
                    label="Komentarz"
                    name="comment"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={true}
                  />
                </div>

                <div className="field col-6">
                  <FormikInputSwitch
                    label="Już dostarczone"
                    name="isDelivered"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                    checked={false}
                  />
                </div>

                <div className="field col-6">
                  <FormikInputSwitch
                    label="Pominięte"
                    name="isMissed"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                    checked={false}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
